import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Cookies from "js-cookie";

interface tinyProps {
	value?: string;
	onEventChangeHandler: (evt: any, editor: any) => void;
}

const TinyMCE = ({ value, onEventChangeHandler }: tinyProps) => {
	const editorRef = useRef(null);

	const example_image_upload_handler: any = (blobInfo: any, progress: any) =>
		new Promise((resolve, reject) => {
			let imageFile = new FormData();
			imageFile.append("file", blobInfo.blob());
			try {
				axios
					.post(`${process.env.REACT_APP_MEDIA_URL}`, imageFile, {
						headers: {
							Authorization: `Bearer ${Cookies.get(
								"sellerToken"
							)}`,
						},
					})
					.then(({ data }) => {
						console.log("data =>", data);
						resolve(
							`${process.env.REACT_APP_IMAGE_URL}${data.path}`
						);
					});
			} catch (error: any) {
				reject(error);
			}
		});

	//   toolbar: 'bold italic underline | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | code |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | undo redo',

	return (
		<div>
			<CKEditor
				editor={ClassicEditor}
				data={value}
				onReady={(editor) => {
					// You can store the "editor" and use when it is needed.
					console.log("Editor is ready to use!", editor);
				}}
				onChange={(event, editor: any) => {
					const data = editor?.getData();
					onEventChangeHandler(data, editor);
				}}
				onBlur={(event, editor) => {
					console.log("Blur.", editor);
				}}
				onFocus={(event, editor) => {
					console.log("Focus.", editor);
				}}
			/>
		</div>
	);

	return (
		<>
			<Editor
				apiKey={`${process.env.REACT_APP_TINYMCE_KEY}`}
				value={value}
				onEditorChange={onEventChangeHandler}
				onInit={(evt: any, editor: any) => (editorRef.current = editor)}
				//initialValue='<p>This is the initial content of the editor.</p>'
				init={{
					height: "550px",
					menubar: false,
					convert_urls: false,
					plugins: [
						"preview",
						"importcss",
						"searchreplace",
						"autolink",
						"autosave",
						"save",
						"directionality",
						"code",
						"visualblocks",
						"visualchars",
						"fullscreen",
						"image",
						"link",
						"media",
						"template",
						"codesample",
						"table",
						"charmap",
						"pagebreak",
						"nonbreaking",
						"anchor",
						"insertdatetime",
						"advlist",
						"lists",
						"wordcount",
						"help",
						"charmap",
						"quickbars",
						"emoticons",
					],
					toolbar:
						" fontsize blocks | bold italic underline | alignleft aligncenter alignright alignjustify |  numlist bullist | image media link | code fullscreen | forecolor backcolor removeformat | pagebreak | charmap emoticons |  preview save print | insertfile | ltr rtl | undo redo",
					images_upload_handler: example_image_upload_handler,
					// setup(editor) {
					//   editor.on("keydown", function(e){
					//     if (tinymce?.activeEditor?.selection) {
					//        var selectedNode = tinymce?.activeEditor.selection.getNode();
					//        if (selectedNode && selectedNode.nodeName == 'IMG') {
					//           var imageSrc = selectedNode as HTMLImageElement;
					//           var imgPathArr = imageSrc.src.split('mm.catalog');
					//           var finalPath = imgPathArr[1];
					//           console.log('object :>> ', finalPath);

					//           axios.delete(process.env.NEXT_PUBLIC_MEDIA_BUCKET_URL + 'catalog' + finalPath, {
					//             headers: {
					//               "Authorization" : `Bearer ${Cookies.get('sellerToken')}`
					//               }
					//           })
					//           //here you can call your server to delete the image
					//        }

					//      }
					//   });
					// }
				}}
			/>
		</>
	);
};
export default TinyMCE;
