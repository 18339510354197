import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setGlobalPopupElement, setGlobalProductPopupElement } from '../../../features/popupSlice'
import { Logical, PageCategory, Widget } from '../../../widgetList/widgetList'
import ImagePopUpV2 from '../../ImagePopUpV2'
import SortList from '../../ListSort'
import PopupWrapper from '../../PopupWrapper'
import ProductManageForm from '../../ProductManageForm'
import { ImageContainerEditButton } from '../../components'
import CloseIcon from '../../icons/CloseIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import PlusIcon from '../../icons/PlusIcon'
import LinkForm, { LinkData } from '../LinkForm'
import ProductsDefaultSettings from '../ProductsDefaultSettings'
import TopPanel, { Panel } from '../TopPanel'

interface ProductItemsType {
    custom?: number [];
    logical?: Logical;
}

interface TabType {
    name: string;
    logo?: string;
    link?: LinkData;
    products?: ProductItemsType
}

interface Props {
    choosenItem: any // type: Widget
    onUpdate: (w: Widget) => void
}

const FormForFilterProducts:FC<Props> = ({choosenItem, onUpdate}) => {
    const dispatch = useDispatch();

    const [active, setActive] = useState<boolean>(choosenItem.active)

    const [title, setTitle] = useState(choosenItem?.data?.title || '')

    const [productTabs, setProductTabs] = useState<TabType []>(choosenItem?.data?.tabs || [] as TabType []);
    
    const [popupElement, setPopupElement] = useState<JSX.Element | null>(null);

    const [selectedCampaign, setSelectedCampaign] = useState<PageCategory | null>(null);

    const [panel, setPanel] = useState<Panel>('main');

    const [colProductLimit, setColProductLimit] = useState<number>(choosenItem?.data?.settings?.productLimit)

    const [settings, setSettings] = useState<any>(choosenItem?.data?.settings as any);


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()

        const widgetObj: any = {
            name: choosenItem.name,
            active: active,
            data : {
                selectedCampaign: selectedCampaign || choosenItem?.data?.selectedCampaign || null,
                tabs: productTabs,
                title: title,
                settings: {
                    productLimit: colProductLimit,
                    ...settings,
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id,
        }
        
        onUpdate(widgetObj)
    }

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const ProductTab = ({
        index,
        tab = {} as TabType,
        onSubmit,
    }: {
        index: number,
        tab?: TabType,
        onSubmit: (tab: TabType) => void,
    }) => {
        const [name, setName] = useState<string>(tab.name);
        const [logo, setLogo] = useState<string>(tab.logo || '');
        const [link, setLink] = useState<LinkData>(tab.link || {} as LinkData);
        const [products, setProducts] = useState<ProductItemsType>(tab.products || {} as ProductItemsType);

        const handleSubmit = (e: any) => {
            e.preventDefault();

            onSubmit({
                name,
                logo,
                link,
                products,
            })
        }

        const handleImageSelector = (index?: number) => {
            dispatch(setGlobalPopupElement(
                <ImagePopUpV2
                    single={true}
                    onUpdate={images => {
                        const arrayImages = images.map((item: any) => {
                            return(
                                    {
                                        image: item.path,
                                        title: item.title,
                                        link: {} as LinkData
                                    }
                            )
                        })
    
                        // setImages(arrayImages)
                        const item = arrayImages[0];
    
                        setLogo(item.image);
    
                        dispatch(setGlobalPopupElement(null));
                    }}
                    onClose={() => {
                        dispatch(setGlobalPopupElement(null));
                    }}
                />
            ))
        }

        const handleLinkData = (data: LinkData, index: number) => {
            setLink(data);
        }

        return (
            <div key={index}
                className='py-4 w-full min-h-[100%] absolute left-0 top-0 z-50 bg-white !block !px-0'
            >
                {/* close button */}
                <button type='button'
                    className='absolute right-0 top-0 z-50 cursor-pointer p-1 rounded-full border border-red-400 bg-red-400'
                    onClick={() => {
                        setPopupElement(null);
                    }}
                >
                    <CloseIcon />
                </button>

                <div
                    className='flex items-center justify-between gap-4 pb-2'
                >
                    <h3 className='underline pb-1'>Tab {index + 1}</h3>
                </div>
                <div
                    className='flex items-center gap-4'
                >
                    <div className='capitalize tracking-wide'>Name:</div>
                    <input value={name} onChange={e => {
                        const title = e.target.value;
                        setName(title);
    
                    }} type="text" placeholder='type here....'/>
                </div>
    
                <div
                    className='flex items-center justify-between py-2 gap-4 w-[120px] h-[60px]'
                >
                    <div className='capitalize tracking-wide'>Logo:</div>
                    <ImageContainerEditButton
                        index={index}
                        imageUrl={logo ? process.env.REACT_APP_IMAGE_URL + logo : ''}
                        handleImageSelector={handleImageSelector}
                    />
                </div>
    
                <LinkForm currnetLinkData={link || {} as LinkData} onUpdate={(data) => {
                    handleLinkData(data, index);
                }}/>
    
                <button type='button'
                    className='w-full text-gray-500 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white'
                    onClick={(e) => {
                        e.preventDefault();
    
                        dispatch(setGlobalProductPopupElement(
                            <PopupWrapper
                                onClose={() => {
                                    dispatch(setGlobalProductPopupElement(null))
                                }}
                            >
                                <ProductManageForm title='Manage Left Slider Products'
                                    logical={products?.logical}
                                    custom={products?.custom}
                                    onUpdate={(logical, custom) => {
                                        setProducts({
                                            logical: logical || undefined,
                                            custom: custom || [],
                                        });
    
                                        dispatch(setGlobalProductPopupElement(null))
                                    }}
                                />
                            </PopupWrapper>
                        ));
                    }}
                >Manage Products</button>
                <div className='my-2 w-full'>
                </div>

                {/* submit btn */}
                <div className='mt-4'>
                    <button onClick={handleSubmit} className='w-full py-3 px-2 bg-yellow-400'>Submit</button>
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit} className='mainSliderForm__container relative'>
            <h1 className='title capitalize'>{choosenItem.name}</h1>

            <TopPanel 
                panel={panel}
                onChange={name => setPanel(name)}
            />
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            
            {popupElement === null && (
                <>
                    {
                        panel === 'main' ? (
                            <div
                                className="py-4 [&>*]:mb-4"
                            >
                                <div>
                                    <div
                                        className='flex justify-between items-center gap-4'
                                    >
                                    <div className='capitalize tracking-wide'>Title :</div>
                                    <button
										className="bg-blue-500 text-white px-2 py-1 rounded-md shadow-lg"
										type="button"
										onClick={() => {
											dispatch(
												setGlobalPopupElement(
													<PopupWrapper>
														<SortList
															data={
																productTabs
															}
															renderElement={(
																item
															) => (
																<div className="flex jusbetween items-center gap-2">
																	{item?.logo && (
                                                                        <img
																		className="rounded-md mb-1 object-contain"
																		src={
																			process
																				.env
																				.REACT_APP_IMAGE_URL +
																			item?.logo
																		}
																		alt=""
																		width={
																			50
																		}
																		height={
																			50
																		}
																	/>
                                                                    )}
																	<div>
																		<p className="text-sm tracking-wide text-center border-b-2">
																			{
																				item?.name
																			}
																		</p>
																		<p className="text-sm tracking-wide text-center border-b-2">
																			{
																				item?.link?.url
																			}
																		</p>
																	</div>
																</div>
															)}
															updateData={(
																data
															) => {
																setProductTabs(data)
																dispatch(
																	setGlobalPopupElement(
																		null
																	)
																);
															}}
														/>
													</PopupWrapper>
												)
											);
										}}>
										Sort
									</button>
                                    </div>
                                    <input value={title} onChange={handleTitle} type="text" placeholder='type here....'/>
                                </div>

                                <div>
                                    {productTabs.map((tab, index) => {

                                        return (
                                            <div key={index}
                                                className='p-2 py-4 rounded-md shadow-lg w-full flex justify-between items-start gap-2'
                                            >
                                                <div>
                                                    <p>Name: {tab.name}</p>
                                                    {tab.logo && (
                                                            <div className='flex items-center gap-4'>
                                                            <div className='capitalize tracking-wide'>Logo:</div>
                                                            <img className='w-[60px] h-[40px] object-contain' src={process.env.REACT_APP_IMAGE_URL + tab.logo} alt=""/>
                                                        </div>
                                                    )}
                                                    {tab.link?.url && (
                                                        <p>Link: 
                                                            <span className='underline opacity-90 ml-2'>/{tab.link?.url}</span>
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <button
                                                        className='text-gray-500 px-2 py-1 rounded-md border border-gray-400 bg-gray-100 hover:bg-white'
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            setPopupElement(
                                                                <ProductTab 
                                                                    index={index}
                                                                    tab={tab}
                                                                    onSubmit={(tab) => {
                                                                        const newTabs = [...productTabs];
                                                                        newTabs[index] = tab;

                                                                        setProductTabs(newTabs);

                                                                        setPopupElement(null);
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >Edit</button>

                                                    <button
                                                        className='text-red-500 px-2 py-1 rounded-md border border-red-400 bg-red-100 hover:bg-white'
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            const newTabs = [...productTabs];
                                                            newTabs.splice(index, 1);

                                                            setProductTabs(newTabs);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </button>

                                                </div>
                                            </div>
                                        )
                                    })}

                                    {/* new add button */}
                                    <button
                                        className='text-gray-500 mt-4 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full flex gap-4'
                                        onClick={(e) => {
                                            e.preventDefault();

                                            setPopupElement(
                                                <ProductTab 
                                                    index={productTabs.length}
                                                    onSubmit={(tab) => {
                                                        setProductTabs([
                                                            ...productTabs,
                                                            tab,
                                                        ])

                                                        setPopupElement(null);
                                                    }}
                                                />
                                            )
                                        }}
                                    ><PlusIcon /> Add {
                                        productTabs.length > 0 ? 'New' : 'Product Tab'
                                    }</button>
                                </div>

                            </div>
                        ) : (
                            <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
                                <div className='flex items-center gap-4'>
                                    <div>Product Limit : </div>
                                    <input className='w-20' type="number" value={colProductLimit} onChange={(e)=> setColProductLimit(Number(e.target.value))}/>
                                </div>

                                <ProductsDefaultSettings 
                                    settings={settings}
                                    onChange={(key, value) => {
                                        setSettings({
                                            ...settings,
                                            [key]: value,
                                        })
                                    }}
                                />
                            </div>
                        )
                    }

                    <div>
                        <button className='mainSliderForm__btn'>save</button>
                    </div>
                </>
            )}

            {popupElement}
        </form>
    )
}

export default FormForFilterProducts