import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../SettingFormItems";

type SettingsNames =
	| "addToCart"
	| "cardWidth"
	| "cardHeight"
	| "cardBottomType"
	| "buyNow"
	| "bgColor"
	| "bgImage"
	| "bgSize"
	| "bgPosition"
	| "bgRepeat"
	| "bgAttachment"
	| "fontColor"
	| "autoplay"
	| "delay";

const productSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Add To Cart Button",
		type: "checkbox",
		key: "addToCart",
	},
	{
		name: "Buy Now Button",
		type: "checkbox",
		key: "buyNow",
	},
	{
		name: "Autoplay",
		key: "autoplay",
		type: "checkbox",
	},
	{
		name: "Autoplay Delay (ms)",
		key: "delay",
		type: "number",
	},
	{
		name: "Card Width",
		type: "number",
		key: "cardWidth",
	},
	{
		name: "Card Height",
		type: "number",
		key: "cardHeight",
	},
	{
		name: "Card Bottom Type",
		type: "select",
		key: "cardBottomType",
		options: [
			{
				value: "auto",
				name: "Hover",
			},
			{
				value: "fixed",
				name: "Fixed",
			},
		],
	},
	{
		key: "bgColor",
		name: "Background Color",
		type: "color",
	},
	{
		key: "bgImage",
		name: "Background Image",
		type: "image",
	},
	{
		key: "fontColor",
		name: "Font Color",
		type: "color",
	},
];

const bgImageSettings: SettingItem<SettingsNames>[] = [
	{
		key: "bgSize",
		name: "Background Size",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Auto",
				value: "auto",
			},
			{
				name: "Cover",
				value: "cover",
			},
			{
				name: "Contain",
				value: "contain",
			},
			{
				name: "100%",
				value: "100%",
			},
		],
	},
	{
		key: "bgPosition",
		name: "Background Position",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Center",
				value: "center",
			},
			{
				name: "Top",
				value: "top",
			},
			{
				name: "Bottom",
				value: "bottom",
			},
			{
				name: "Left",
				value: "left",
			},
			{
				name: "Right",
				value: "right",
			},
		],
	},
	{
		key: "bgRepeat",
		name: "Background Repeat",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "No Repeat",
				value: "no-repeat",
			},
			{
				name: "Repeat",
				value: "repeat",
			},
			{
				name: "Repeat X",
				value: "repeat-x",
			},
			{
				name: "Repeat Y",
				value: "repeat-y",
			},
		],
	},
	{
		key: "bgAttachment",
		name: "Background Attatchment",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Scroll",
				value: "scroll",
			},
			{
				name: "Fixed",
				value: "fixed",
			},
		],
	},
];

const ProductsDefaultSettings = ({
	settings,
	onChange,
}: {
	settings?: SettingItems<SettingsNames>;
	onChange: (key: string, value: string) => void;
}) => {
	return (
		<>
			<SettingFormItems
				settingItems={productSettings}
				settings={settings}
				onChange={onChange}
			/>

			{settings?.bgImage && (
				<SettingFormItems
					settingItems={bgImageSettings}
					title="Background Image Settings"
					settings={settings}
					onChange={onChange}
				/>
			)}
		</>
	);
};

export default ProductsDefaultSettings;
