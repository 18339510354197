import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { Widget } from "../../../widgetList/widgetList";
import ImagePopUpV2 from "../../ImagePopUpV2";
import { ImageSelectorV2 } from "../../ImageSelector";
import { ImageContainerEditButton } from "../../components";
import CloseIcon from "../../icons/CloseIcon";
import LinkForm, { LinkData } from "../LinkForm";

import ImageSortWithTitle from "../../ImageSortWithTitle";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../../SettingFormItems";
import "./formForSlider.css";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

type Panel = "main" | "settings";
const panels: {
	name: Panel;
	title: string;
}[] = [
	{
		name: "main",
		title: "Main",
	},
	{
		name: "settings",
		title: "Settings",
	},
];

type SettingsNames = "width" | "height" | "autoplay" | "delay";

const bannerSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Width",
		key: "width",
		type: "number",
	},
	{
		name: "Height",
		key: "height",
		type: "number",
	},
	{
		name: "Autoplay",
		key: "autoplay",
		type: "checkbox",
	},
	{
		name: "Autoplay Delay (ms)",
		key: "delay",
		type: "number",
	},
];

interface Image {
	image: string;
	title: string;
	link: LinkData;
}

const FromForSlider: FC<Props> = ({ choosenItem, onUpdate }) => {
	// const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
	const [active, setActive] = useState<boolean>(choosenItem.active);
	const [images, setImages] = useState<Image[]>([]);
	const { pageTitle } = useParams();

	const dispatch = useDispatch();

	const [panel, setPanel] = useState<Panel>("main");
	const [containerType, setContainerType] = useState<"full" | "box">(
		(choosenItem as any)?.data?.settings?.containerType || "box"
	);
	const prevSettings = (choosenItem as any)?.data?.settings as any;
	const [settings, setSettings] = useState<SettingItems<SettingsNames>>({
		...prevSettings,
		...(prevSettings?.autoplay === undefined ? { autoplay: true } : {}),
		...(prevSettings?.delay === undefined ? { delay: 5000 } : {}),
	});

	// useEffect(()=>{
	//     const arrayImages = choosenAllImages.map((item: any) => {
	//         return(
	//                 {
	//                     image: item.path,
	//                     title: item.title,
	//                     link: item.link
	//                 }
	//         )
	//     })

	//     setImages((pImages)=> {
	//         return pImages.concat(arrayImages)
	//     })

	// },[choosenAllImages])

	useEffect(() => {
		setImages(choosenItem.data?.images || []);
	}, [choosenItem]);

	const handleTitle = (
		e: React.ChangeEvent<HTMLInputElement>,
		image: any
	) => {
		setImages((pImages) => {
			return pImages.map((item) => {
				const copyItem = { ...item };
				if (item.image === image?.image) {
					copyItem.title = e.target.value;
				}
				return copyItem;
			});
		});
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const widgetObj = {
			name: "slider",
			active: active,
			data: {
				images: images,
				settings: {
					...settings,
					containerType: containerType,
				},
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		console.log("widgetObj =", widgetObj);

		const actionObj = {
			widget: widgetObj,
			pTitle: "home_page",
		};

		// dispatch(handlePageWidget(actionObj))

		onUpdate(widgetObj);
	};

	const removeImagesFromLOaclState = (index: any) => {
		const filterImages = images.filter((_, i) => i !== index);
		setImages(filterImages);
	};

	const handleRemoveImage = (index: number) => {
		removeImagesFromLOaclState(index);
		const payloadObj = {
			index,
			id: choosenItem.id,
			pageTitle,
		};
		// dispatch(removeImage(payloadObj))
	};

	const handleLinkData = (data: LinkData, image?: any) => {
		setImages((pImages) => {
			return pImages.map((item) => {
				const copyItem = { ...item };
				if (item.image === image?.image) {
					copyItem.link = data;
				}
				return copyItem;
			});
		});
	};

	const handleImageSelector = (index?: number) => {
		dispatch(
			setGlobalPopupElement(
				<ImagePopUpV2
					single={index !== undefined}
					onUpdate={(images) => {
						const arrayImages = images.map((item: any) => {
							return {
								image: item.path,
								title: item.title,
								link: {} as LinkData,
							};
						});

						if (index === undefined) {
							setImages((prev) => {
								return prev.concat(arrayImages);
							});
						} else {
							setImages((prev) => {
								const copyPrev = [...prev];
								copyPrev[index] = {
									...copyPrev[index],
									image: arrayImages[0].image,
								};
								return copyPrev;
							});
						}

						dispatch(setGlobalPopupElement(null));
					}}
					onClose={() => {
						dispatch(setGlobalPopupElement(null));
					}}
				/>
			)
		);
	};

	return (
		<>
			{choosenItem.name === "slider" ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<h1 className="title capitalize">Main Slider</h1>

					{/* panels switch */}
					<div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
						{panels.map((p) => (
							<button
								key={p.name}
								type="button"
								className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
									panel === p.name
										? "border-green-500 text-black"
										: "text-black/50 hover:text-black/70"
								}`}
								onClick={() => setPanel(p.name)}>
								{p.title}
							</button>
						))}
					</div>

					{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

					{panel === "main" ? (
						<div>
							<ImageSortWithTitle
								title={`Images (${settings?.width ?? 1920}x${
									settings?.height ?? 710
								})`}
								data={images}
								setData={setImages}
							/>
							{images.map((image: any, index: number) => {
								return (
									<div
										key={image?.name}
										className="relative mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md">
										{/* <div className='relative'>
                                        <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+image?.image} alt="" />
                                        <div className='absolute flex justify-center items-center top-0 right-0 w-full h-full bg-black/50 transition-opacity duration-500 opacity-0 hover:opacity-100'>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                handleImageSelector(index);
                                            }} className='p-2 [&>*]:stroke-white'>
                                                <EditIcon />
                                            </button>
                                        </div>
                                    </div> */}
										<ImageContainerEditButton
											index={index}
											imageUrl={
												process.env
													.REACT_APP_IMAGE_URL +
												image?.image
											}
											handleImageSelector={
												handleImageSelector
											}
										/>
										<input
											className="mb-1"
											value={image?.title}
											type="text"
											placeholder="title :"
											onChange={(e) =>
												handleTitle(e, image)
											}
										/>
										<LinkForm
											currnetLinkData={image?.link}
											onUpdate={handleLinkData}
											selectedImage={image}
										/>
										<div
											className="image__popup--close-btn"
											onClick={() =>
												handleRemoveImage(index)
											}>
											<CloseIcon />
										</div>
									</div>
								);
							})}
						</div>
					) : panel === "settings" ? (
						<div>
							{/* image container full or box radio setting */}
							<div className="flex flex-col">
								<div>Image container: </div>
								<div className="[&>input]:!w-4 [&>input]:mx-2">
									<input
										type="radio"
										id="b-type-full"
										name="imageContainer"
										value="full"
										onChange={(e) =>
											setContainerType(
												e.target.value as "full" | "box"
											)
										}
										checked={containerType === "full"}
									/>
									<label htmlFor="b-type-full">Full</label>

									<input
										type="radio"
										id="b-type-box"
										name="imageContainer"
										value="box"
										onChange={(e) =>
											setContainerType(
												e.target.value as "full" | "box"
											)
										}
										checked={containerType === "box"}
									/>
									<label htmlFor="b-type-box">Box</label>
								</div>
							</div>

							<SettingFormItems
								settings={settings}
								settingItems={bannerSettings}
								onChange={(key, value) => {
									setSettings((prev) => ({
										...prev,
										[key]: value,
									}));
								}}
							/>
						</div>
					) : null}
					{/* <ImageSelector /> */}
					<ImageSelectorV2 onClick={handleImageSelector} />

					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FromForSlider;
