import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { PageCategory, Widget } from "../../../widgetList/widgetList";
import CampaignSelector from "../../CampaignSelector";
import ImagePopUpV2 from "../../ImagePopUpV2";
import { ImageSelectorV2 } from "../../ImageSelector";
import LogicBuilder from "../../LogicBuilder";
import { ImageContainerEditButton } from "../../components";
import LinkForm, { LinkData } from "../LinkForm";
import ProductsDefaultSettings from "../ProductsDefaultSettings";
import TopPanel, { Panel } from "../TopPanel";

interface Props {
	choosenItem: any; // type: Widget
	onUpdate: (w: Widget) => void;
}

const FormForThreeBannerProducts: FC<Props> = ({ choosenItem, onUpdate }) => {
	const dispatch = useDispatch();

	const customProductIds = useSelector(
		(state: RootState) => state.logicBuilder.setting
	);

	const [active, setActive] = useState<boolean>(choosenItem.active);

	const [featuredImage, setFeatiredImage] = useState("");
	const [title, setTitle] = useState(choosenItem?.data?.title || "");
	const [link, setLink] = useState<LinkData>({} as LinkData);
	const [fetcturedImagePosition, setFetcturedImagePosition] =
		useState("left");
	const [sourceType, setSourceType] = useState("custom");
	const [rightImages, setRightImages] = useState<any[]>([]);

	const [rightImageOne, setRightImageOne] = useState("");
	const [rightImageOneTitle, setRightImageOneTitle] = useState("");
	const [rightImageOneLink, setRightImageOneLink] = useState<LinkData>(
		{} as LinkData
	);

	const [rightImageTwo, setRightImageTwo] = useState("");
	const [rightImageTwoTitle, setRightImageTwoTitle] = useState("");
	const [rightImageTwoLink, setRightImageTwoLink] = useState<LinkData>(
		{} as LinkData
	);

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	const [panel, setPanel] = useState<Panel>("main");

	const [settings, setSettings] = useState<any>(
		choosenItem?.data?.settings as any
	);

	useEffect(() => {
		const customLenght: any = choosenItem?.data?.setting?.custom?.length;
		if (customLenght > 0) {
			setSourceType("custom");
		} else {
			setSourceType("logical");
		}
	}, [choosenItem]);

	useEffect(() => {
		setFeatiredImage(choosenItem?.featuredImage || "");
		setFetcturedImagePosition(choosenItem?.fetcturedImagePosition || "");
		setLink(
			(choosenItem?.fetchuredImageUrl as LinkData) || ({} as LinkData)
		);
		setTitle(choosenItem?.data?.title || "");
		//setRightImages(choosenItem?.rightImages || [])
		setRightImageOne(
			choosenItem?.data?.rightImages?.rightImageOne?.image || ""
		);
		setRightImageTwo(
			choosenItem?.data?.rightImages?.rightImageTwo?.image || ""
		);

		setRightImageOneTitle(
			choosenItem?.data?.rightImages?.rightImageOne?.title || ""
		);
		setRightImageTwoTitle(
			choosenItem?.data?.rightImages?.rightImageTwo?.title || ""
		);

		setRightImageOneLink(
			(choosenItem?.data?.rightImages?.rightImageOne?.link as LinkData) ||
				({} as LinkData)
		);
		setRightImageTwoLink(
			(choosenItem?.data?.rightImages?.rightImageTwo?.link as LinkData) ||
				({} as LinkData)
		);
	}, [choosenItem]);

	const handleImageSelector = (
		type: "featured" | "right-one" | "right-two" | "right-three",
		index?: number
	) => {
		dispatch(
			setGlobalPopupElement(
				<ImagePopUpV2
					single={true}
					onUpdate={(images) => {
						const arrayImages = images.map((item: any) => {
							return {
								image: item.path,
								title: item.title,
								link: {} as LinkData,
							};
						});

						const image = arrayImages[0];

						switch (type) {
							case "featured":
								setFeatiredImage(image.image);
								break;
							case "right-one":
								setRightImageOne(image.image);
								break;
							case "right-two":
								setRightImageTwo(image.image);
								break;
						}

						dispatch(setGlobalPopupElement(null));
					}}
					onClose={() => {
						dispatch(setGlobalPopupElement(null));
					}}
				/>
			)
		);
	};

	// useEffect(()=>{
	//     setRightImages(rightImagesFromStore)
	// },[rightImagesFromStore])

	const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleLinkData = (data: LinkData) => {
		setLink(data);
	};

	//right image one
	const handleRightImageOneTitle = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setRightImageOneTitle(e.target.value);
	};
	const handleRightImageOneLinkData = (data: LinkData) => {
		console.log("right image one", data);
		setRightImageOneLink(data);
	};

	//right image two
	const handleRightImageTwoTitle = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setRightImageTwoTitle(e.target.value);
	};
	const handleRightImageTwoLinkData = (data: LinkData) => {
		console.log("right image two", data);
		setRightImageTwoLink(data);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.log("customProductIds =", customProductIds);
		const widgetObj: any = {
			name: choosenItem.name,
			active: active,
			featuredImage: featuredImage,
			fetchuredImageUrl: link,
			fetcturedImagePosition: fetcturedImagePosition,
			rightImages: rightImages,
			data: {
				images: [],
				title: title,
				setting:
					sourceType === "custom"
						? { custom: customProductIds.custom, logical: {} }
						: { custom: [], logical: customProductIds.logical },
				settings: settings,
				rightImages: {
					rightImageOne: {
						image: rightImageOne,
						title: rightImageOneTitle,
						link: rightImageOneLink,
					},
					rightImageTwo: {
						image: rightImageTwo,
						title: rightImageTwoTitle,
						link: rightImageTwoLink,
					},
				},
				selectedCampaign:
					selectedCampaign ||
					choosenItem?.data?.selectedCampaign ||
					null,
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		onUpdate(widgetObj);
	};

	return (
		<form onSubmit={handleSubmit} className="mainSliderForm__container">
			<h1 className="title capitalize">{choosenItem.name}</h1>

			<TopPanel panel={panel} onChange={(name) => setPanel(name)} />
			{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

			{panel === "main" && (
				<div>
					<div className="border-b pb-4">
						<div>Featured Image (1320x355): </div>
						{featuredImage.length > 0 ? (
							<ImageContainerEditButton
								index={0}
								imageUrl={
									process.env.REACT_APP_IMAGE_URL +
									featuredImage
								}
								handleImageSelector={(index) =>
									handleImageSelector("featured", index)
								}
							/>
						) : (
							// <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + featuredImage} alt="" /> :
							<img
								className="rounded-md mt-2"
								src="/img/image-placeholder.jpg"
								alt=""
							/>
						)}
						<input
							value={title}
							onChange={handleTitle}
							type="text"
							className="my-2"
							placeholder="Title"
						/>
						<LinkForm
							currnetLinkData={link}
							onUpdate={handleLinkData}
						/>

						{/* <ImageSelector type="featured"/> */}
						<ImageSelectorV2
							onClick={() => handleImageSelector("featured")}
						/>
					</div>

					<div className="py-4">
						<div className="capitalize">
							Right Image (One) (315x200):{" "}
						</div>
						<div>
							<div className="pb-4">
								{rightImageOne.length > 0 ? (
									<ImageContainerEditButton
										index={0}
										imageUrl={
											process.env.REACT_APP_IMAGE_URL +
											rightImageOne
										}
										handleImageSelector={(index) =>
											handleImageSelector(
												"right-one",
												index
											)
										}
									/>
								) : (
									// <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + rightImageOne} alt="" /> :
									<img
										className="rounded-md mt-2"
										src="/img/image-placeholder.jpg"
										alt=""
									/>
								)}
								<input
									value={rightImageOneTitle}
									onChange={handleRightImageOneTitle}
									type="text"
									className="my-2"
									placeholder="Title"
								/>
								<LinkForm
									currnetLinkData={rightImageOneLink}
									onUpdate={handleRightImageOneLinkData}
								/>

								{/* <ImageSelector type="right-one"/> */}
								<ImageSelectorV2
									onClick={() =>
										handleImageSelector("right-one")
									}
								/>
							</div>
							{/* {
                                rightImages?.map(item => {
                                    return (
                                        <img src={process.env.REACT_APP_IMAGE_URL+item.name} alt="" />
                                    )
                                })      
                            } */}
						</div>
						{/* <div>
                            <RightImages />
                        </div> */}
					</div>

					<div className="py-4">
						<div className="capitalize">
							Right Image (two) (315x200):{" "}
						</div>
						<div>
							<div className="pb-4">
								{rightImageTwo.length > 0 ? (
									<ImageContainerEditButton
										index={0}
										imageUrl={
											process.env.REACT_APP_IMAGE_URL +
											rightImageTwo
										}
										handleImageSelector={(index) =>
											handleImageSelector(
												"right-two",
												index
											)
										}
									/>
								) : (
									// <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + rightImageTwo} alt="" /> :
									<img
										className="rounded-md mt-2"
										src="/img/image-placeholder.jpg"
										alt=""
									/>
								)}
								<input
									value={rightImageTwoTitle}
									onChange={handleRightImageTwoTitle}
									type="text"
									className="my-2"
									placeholder="Title"
								/>
								<LinkForm
									currnetLinkData={rightImageTwoLink}
									onUpdate={handleRightImageTwoLinkData}
								/>

								{/* <ImageSelector type="right-two"/> */}
								<ImageSelectorV2
									onClick={() =>
										handleImageSelector("right-two")
									}
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
						<CampaignSelector
							initialCampaign={
								choosenItem?.data?.selectedCampaign || null
							}
							onUpdate={(data) => {
								setSelectedCampaign(data);
							}}
						/>
					</div>

					<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
						<div>Source type: </div>
						<div className="flex items-center gap-4">
							<div
								onClick={() => setSourceType("logical")}
								className="flex items-center gap-2 cursor-pointer">
								<div
									className={`w-5 h-5 rounded-md ${
										sourceType === "logical"
											? "bg-green-500 shadow"
											: "bg-gray-300 shadow-inner"
									}`}></div>
								<div>Logical</div>
							</div>
							<div
								onClick={() => setSourceType("custom")}
								className="flex items-center gap-2 cursor-pointer">
								<div
									className={`w-5 h-5 rounded-md ${
										sourceType === "custom"
											? "bg-green-500 shadow"
											: "bg-gray-300 shadow-inner"
									}`}></div>
								<div>Custom</div>
							</div>
						</div>
					</div>

					<LogicBuilder
						sourceType={sourceType}
						choosenItem={choosenItem}
					/>
				</div>
			)}

			{panel === "settings" && (
				<ProductsDefaultSettings
					settings={settings}
					onChange={(key, value) => {
						setSettings({
							...settings,
							[key]: value,
						});
					}}
				/>
			)}

			<div>
				<button className="mainSliderForm__btn">save</button>
			</div>
		</form>
	);
};

export default FormForThreeBannerProducts;
