import {_axios} from '../../../api'
import React, { FC, useEffect, useState } from 'react'
import { Widget } from '../../../widgetList/widgetList'
import { useDetectClickOutside } from 'react-detect-click-outside';
import CloseIcon from '../../icons/CloseIcon';
import Category from '../../Widgets/Category';
import { checkToken } from '../../../App';
import Cookies from 'js-cookie';
import { adminTokenCookieName } from '../../../utils/constants';
import { getBrandList } from '../../../utils/requests';
import { CategoryItem } from '../../../types/category';

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

interface Category {
    id: number,
    logo: string,
    name: string,
    slug: string
}

const FormForSellerList:FC<Props> = ({choosenItem, onUpdate}) => {

    const [active, setActive] = useState(false)
    const [text, setText] = useState('')
    const [sellers, setSellers] = useState<CategoryItem []>([])
    const [show, setShow] = useState(true)
    const [choosenSellers, setChoosenSellers] = useState<Category[]>([])
    const [sellerArray, setSellerArray] = useState<number[]>([])

    useEffect(()=>{
        setActive(choosenItem.active)
        setSellerArray(choosenItem.sellerList || [])
    },[choosenItem])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setText(e.target.value)
        getSellers()
    }

    const getSellers = async() => {
        checkToken()
        await getBrandList({  }).then(res => {
            setSellers(res.data)
            setShow(true)
        })
    }

    const hideBrandList = () => {
        setShow(false)
    }

    const handleClick = (brand: CategoryItem, index: number) => {
        const newArray = [...choosenSellers]
        newArray.push(brand as any)
        setChoosenSellers(newArray.reverse())

        // let newCategoryArray:number[] = [] 
        // newArray.reverse().forEach((item: Category)=>{
        //     newCategoryArray.push(item.id)
        // })
        // setCategoryArray(newCategoryArray)

        const newCategoryArray = [...sellerArray]
        newCategoryArray.push(brand.id)
        setSellerArray(newCategoryArray)
    } 

    const removeCategory = (index: number) =>{
        const filteredCategories = choosenSellers.filter((_,i)=> i !== index)
        setChoosenSellers(filteredCategories)
    }
 
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const brandObj = {
            name: 'brand-list',
            active: active,
            sellerList: sellerArray,
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(brandObj)
    }

    const handleRemoveId = (index :number) => {
        const filteredCategoryId = sellerArray.filter((_,i) => i !== index)
        setSellerArray(filteredCategoryId)
    }

    const ref = useDetectClickOutside({ onTriggered: hideBrandList });

  return (
    <>
    { choosenItem.name === 'brand-list' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>Brand List</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            <div>
                {
                    sellerArray.length > 0 && 
                    <div className=''>
                        <div className='capitalize'>selected brands:</div>
                        <div className="grid grid-cols-4 gap-4 mt-4">
                        {
                            sellerArray.map((item: number, index: number)=>{
                                return (
                                    <div key={index} className="relative h-12 bg-white shadow-lg flex items-center justify-center rounded-md">
                                        <div>{item}</div>
                                        <div onClick={()=>handleRemoveId(index)} className='absolute -top-3 -right-3 bg-white text-red-500 rounded-full shadow-lg p-1 cursor-pointer'>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                }
            </div>
            <div>
                {/* <div>
                    {
                        choosenCategories.length > 0 && 
                        <div>
                            {
                                choosenCategories.map((choosenCategory, index) => {
                                    return(
                                        <div key={index} className="bg-white shadow-lg mb-3 px-3 rounded-md relative">
                                            <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                <div className='text-sm font-light tracking-wide truncate'>{choosenCategory.name}</div>
                                            </div>
                                            <div onClick={() => removeCategory(index)} className='absolute z-10 -top-3 -right-3 bg-white text-red-500 rounded-full p-1 shadow-lg cursor-pointer'>
                                                <CloseIcon/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div> */}
                <div>
                    <input type="text" placeholder='Search categories...' onChange={handleSearch}/>
                </div>
                <div>
                    {
                        sellers.length > 0 && show &&
                        <div className='divide-y max-h-64 min-h-fit overflow-y-scroll' ref={ref}>
                            {
                                sellers.map((seller, index) => {
                                    return (
                                        <div key={index} onClick={()=>handleClick(seller, index)}>
                                            <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                <div>{seller.id}</div>
                                                <div className='text-sm font-light tracking-wide truncate'>{seller.name}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForSellerList