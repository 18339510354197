import { useState } from "react";
import { useDispatch } from "react-redux";
import { CustomFormProps } from ".";
import { setGlobalPopupElement } from "../../features/popupSlice";
import { CWImageType } from "../../types/widget";
import ImagePopUpV2 from "../ImagePopUpV2";
import SettingFormItems, {
  SettingItem,
  SettingItems,
} from "../SettingFormItems";
import { ImageContainerEditButton } from "../components";
import CloseIcon from "../icons/CloseIcon";
import LinkForm, { LinkData } from "../widgetFrom/LinkForm";
import classes from "./CWForm.module.css";

export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

type SettingsNames = "resetTime" | "numberOfTimes";

const productSettings: SettingItem<SettingsNames>[] = [
  {
    name: "Reset Time (in minutes)",
    type: "number",
    key: "resetTime",
  },
  {
    name: "Number of Times",
    type: "number",
    key: "numberOfTimes",
  },
];

// welcome popup

const WelcomePopupForm = ({
  formItems,
  customForm = false,
  name = "",
  onSubmit = () => {},
  title,
  className,
  style,
}: CustomFormProps) => {
  const dispatch = useDispatch();

  const [settings, setSettings] = useState<SettingItems<SettingsNames>>(
    formItems as any
  );

  const [image, setImage] = useState<CWImageType>(
    formItems?.banner?.image || ({} as any)
  );

  const [mobileImage, setMobileImage] = useState<CWImageType>(
    formItems?.banner2?.image || ({} as any)
  );

  const classNames = [classes.CWForm, className];

  const handleSubmit = () => {
    const data: any = {
      ...settings,
      banner: {
        ...(settings as any).banner,
        image: image,
      },
      banner2: {
        ...(settings as any).banner2,
        image: mobileImage,
      },
    };

    onSubmit(data as any);
  };

  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>, image: any) => {
    setImage((prev) => {
      return {
        ...prev,
        title: e.target.value,
      };
    });
  };

  const handleLinkData = (data: LinkData, image?: any) => {
    setImage((prev) => {
      return {
        ...prev,
        link: data,
      };
    });
  };

  const handleMobileLinkData = (data: LinkData, image?: any) => {
    setMobileImage((prev) => {
      return {
        ...prev,
        link: data,
      };
    });
  };

  const handleImageSelector = (index?: number) => {
    dispatch(
      setGlobalPopupElement(
        <ImagePopUpV2
          single={true}
          onUpdate={(images) => {
            const arrayImages: CWImageType[] = images.map((item: any) => {
              return {
                url: item.path,
                title: item.title,
                link: {} as LinkData,
                name: item.name,
              };
            });

            setImage(arrayImages[0]);

            dispatch(setGlobalPopupElement(null));
          }}
          onClose={() => {
            dispatch(setGlobalPopupElement(null));
          }}
        />
      )
    );
  };

  const handleMobileImageSelector = (index?: number) => {
    dispatch(
      setGlobalPopupElement(
        <ImagePopUpV2
          single={true}
          onUpdate={(images) => {
            const arrayImages: CWImageType[] = images.map((item: any) => {
              return {
                url: item.path,
                title: item.title,
                link: {} as LinkData,
                name: item.name,
              };
            });

            setMobileImage(arrayImages[0]);

            dispatch(setGlobalPopupElement(null));
          }}
          onClose={() => {
            dispatch(setGlobalPopupElement(null));
          }}
        />
      )
    );
  };

  const handleRemoveImage = () => {
    setImage({} as any);
  };

  const handleRemoveMobileImage = () => {
    setMobileImage({} as any);
  };

  return (
    <div className={classNames.join(" ")} style={style}>
      <form
        className="mainSliderForm__container"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h1 className="title capitalize">{title}</h1>

        <div className="mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative">
          <p>Desktop Image (1200x720)</p>
          <ImageContainerEditButton
            imageUrl={
              image?.url
                ? process.env.REACT_APP_IMAGE_URL + image?.url
                : "/placeholder.png"
            }
            handleImageSelector={handleImageSelector}
          />
          <LinkForm
            currnetLinkData={image?.link}
            onUpdate={handleLinkData}
            selectedImage={image}
          />
          <div
            className="image__popup--close-btn"
            onClick={() => handleRemoveImage()}
          >
            <CloseIcon />
          </div>
        </div>

        {/* mobile pop up image */}

        <div className="mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative">
          <p>Mobile Image (340x600)</p>
          <ImageContainerEditButton
            imageUrl={
              mobileImage?.url
                ? process.env.REACT_APP_IMAGE_URL + mobileImage?.url
                : "/placeholder.png"
            }
            handleImageSelector={handleMobileImageSelector}
          />
          <LinkForm
            currnetLinkData={mobileImage?.link}
            onUpdate={handleMobileLinkData}
            selectedImage={mobileImage}
          />
          <div
            className="image__popup--close-btn"
            onClick={() => handleRemoveMobileImage()}
          >
            <CloseIcon />
          </div>
        </div>

        <SettingFormItems
          title="Page Settings"
          settings={settings}
          settingItems={productSettings}
          onChange={(key, value) => {
            setSettings((prev) => ({
              ...prev,
              [key]: value,
            }));
          }}
        />

        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="mainSliderForm__btn"
          >
            save
          </button>
        </div>
      </form>
    </div>
  );
};

export default WelcomePopupForm;
