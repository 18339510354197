import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useDispatch } from "react-redux";
import { checkToken } from "../../../App";
import { _axios } from "../../../api";
import {
	getLogicalBrand,
	getLogicalCampaign,
	getLogicalCategory,
	getLogicalSeller,
	setLogialBrandOrder,
	setLogialCampaignOrder,
	setLogialCategoryOrder,
	setLogialSellerOrder,
	setLogicalIsFeatured,
} from "../../../features/logicBuilder/logicBuilder";
import { adminTokenCookieName } from "../../../utils/constants";
import { getBrandList, getCategoryList } from "../../../utils/requests";
import { Logical, LogicalObj, Widget } from "../../../widgetList/widgetList";
import CloseIcon from "../../icons/CloseIcon";
import "./logicalbuilder.css";

interface CategoryObj {
	id: number;
	name: string;
	limit: string;
	order: string;
}

interface SellerObj {
	name: string;
	id: number;
}

interface Props {
	choosenItem: Widget;
	sourceType: string;
}

export type LogicalOrderTypes = "asc" | "desc" | "random";
type LogicalOrderKeys =
	| "categoryOrder"
	| "brandOrder"
	| "sellerOrder"
	| "campaignOrder";

export type LogicalOrderObjects = {
	[key in LogicalOrderKeys]: LogicalOrderTypes;
};

const orderArray: LogicalOrderTypes[] = ["desc", "asc", "random"];

const LogicalBuilder: FC<Props> = ({ sourceType, choosenItem }) => {
	const dispatch = useDispatch();

	const choosenCategories =
		choosenItem?.data?.setting?.logical?.category || [];
	const choosenBrands = choosenItem?.data?.setting?.logical?.brand || [];
	const choosenSellers = choosenItem?.data?.setting?.logical?.seller || [];
	const choosenCampaigns =
		choosenItem?.data?.setting?.logical?.campaign || [];

	const logicalOrderByGlobal: LogicalOrderObjects = {
		categoryOrder:
			choosenItem?.data?.setting?.logical?.categoryOrder || "desc",
		brandOrder: choosenItem?.data?.setting?.logical?.brandOrder || "desc",
		sellerOrder: choosenItem?.data?.setting?.logical?.sellerOrder || "desc",
		campaignOrder:
			choosenItem?.data?.setting?.logical?.campaignOrder || "desc",
	};

	const choosenIsFeatured =
		choosenItem?.data?.setting?.logical?.isFeatured || false;

	useEffect(() => {
		setMainCategory(choosenCategories);
		setMainBrand(choosenBrands);
		setMainSelles(choosenSellers);
		setMainCampaigns(choosenCampaigns);

		dispatch(getLogicalCategory(choosenCategories));
		dispatch(getLogicalBrand(choosenBrands));
		dispatch(getLogicalCampaign(choosenCampaigns));
		dispatch(getLogicalSeller(choosenSellers));

		setOrderBy(logicalOrderByGlobal);

		dispatch(setLogialCategoryOrder(logicalOrderByGlobal.categoryOrder));
		dispatch(setLogialBrandOrder(logicalOrderByGlobal.brandOrder));
		dispatch(setLogialSellerOrder(logicalOrderByGlobal.sellerOrder));
		dispatch(setLogialCampaignOrder(logicalOrderByGlobal.campaignOrder));

		dispatch(setLogicalIsFeatured(choosenIsFeatured));
	}, [choosenItem]);

	const [limit, setLimit] = useState<string>("");

	//for category search
	const [mainCategory, setMainCategory] = useState<LogicalObj[]>([]);
	const [serchTest, setSearchText] = useState("");
	const [toggleCategoryResult, setToggleCategoryResult] = useState(false);
	const [categories, setCategories] = useState([]);
	const [categoryList, setCategoryList] = useState<CategoryObj[]>([]);
	const [categoryLimit, setCategoryLimit] = useState("");
	const [categoryOrder, setCategoryORder] = useState("asc");

	//for brand search
	const [mainBrand, setMainBrand] = useState<LogicalObj[]>([]);
	const [serachBrandText, setSearchBrandText] = useState("");
	const [toggleBrandResult, setToggleBrandResult] = useState(false);
	const [brands, setBrands] = useState([]);
	const [brandList, setBrandList] = useState<CategoryObj[]>([]);
	const [brandLimit, setBrandLimit] = useState("");
	const [brandOrder, setBrandOrder] = useState("asc");

	//for seller search
	const [mainSelles, setMainSelles] = useState<LogicalObj[]>([]);
	const [serachSellerText, setSearchSellerText] = useState("");
	const [toggleSellerResult, setToggleSellerResult] = useState(false);
	const [sellers, setSellers] = useState([]);
	const [sellerList, setSellerList] = useState<LogicalObj[]>([]);

	//for campaign search
	const [mainCampaigns, setMainCampaigns] = useState<LogicalObj[]>([]);
	const [searchCampaignText, setSearchCampaignText] = useState("");
	const [toggleCampaignResult, setToggleCampaignResult] = useState(false);
	const [campaigns, setCampaigns] = useState([]);
	const [campaignList, setCampaignList] = useState<CategoryObj[]>([]);

	// for order
	const [orderBy, setOrderBy] = useState<LogicalOrderObjects>({
		categoryOrder: "desc",
		brandOrder: "desc",
		sellerOrder: "desc",
		campaignOrder: "desc",
	});

	const [isFeatured, setIsFeatured] = useState<boolean>(choosenIsFeatured);

	//for category search
	const handleSearchCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleCategoryResult(true);
		}
		getCategory(text);
	};
	const getCategory = async (text: string) => {
		checkToken();
		getCategoryList({
			search: text,
			status: "active",
		}).then((data) => {
			setCategories(data.data as any);
		});
	};
	const handleCategoryLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const categoryLimit: string = e.target.value.replace(/\D/g, "");

		const _mainCategory = mainCategory.map((category: LogicalObj) => {
			if (category.id === item.id) {
				return { ...category, limit: categoryLimit };
			}
			return category;
		});

		setMainCategory(_mainCategory);
		dispatch(getLogicalCategory(_mainCategory));
	};
	const handleCategory = (item: LogicalObj) => {
		setToggleCategoryResult(false);
		// const categoryObj = {
		//     id: item.id,
		//     name: item.name,
		//     limit: categoryLimit,
		//     order: categoryOrder
		// }
		// const _categoryList = [...categoryList]
		// _categoryList.push(categoryObj)
		// setCategoryList(_categoryList)

		const _id = item.id;
		const _limit = item.limit;
		const _name = item.name;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
		};

		const _mainCategory = [...mainCategory];
		_mainCategory.push(_newObj);
		setMainCategory(_mainCategory);
		setSearchText("");
	};
	const handleDeleteCategory = (index: number) => {
		const filteredCategory = mainCategory.filter((_, i) => i !== index);
		setMainCategory(filteredCategory);
		dispatch(getLogicalCategory(filteredCategory));
	};

	//for brand search
	const handleSearchBrand = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchBrandText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleBrandResult(true);
		}
		getBrand(text);
	};
	const getBrand = async (text: string) => {
		checkToken();
		getBrandList({
			search: text,
			status: "active",
		}).then((data) => {
			setBrands(data.data as any);
		});
	};
	const handleBrandLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const brandLimit: string = e.target.value.replace(/\D/g, "");
		const _mainBrand = mainBrand.map((brand: LogicalObj) => {
			if (brand.id === item.id) {
				return { ...brand, limit: brandLimit };
			}
			return brand;
		});
		setMainBrand(_mainBrand);
		dispatch(getLogicalBrand(_mainBrand));
	};
	const handleBrand = (item: any) => {
		setToggleBrandResult((pVal) => !pVal);
		// const brandObj = {
		//     id: item.id,
		//     name: item.name,
		//     limit: categoryLimit,
		//     order: categoryOrder
		// }
		// const _brandList = [...brandList]
		// _brandList.push(brandObj)
		// setBrandList(_brandList)

		const _id = item.id;
		const _limit = item.limit;
		const _name = item.name;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
		};

		const _mainBrand = [...mainBrand];
		_mainBrand.push(_newObj);
		setMainBrand(_mainBrand);
		setSearchBrandText("");
	};
	const handleDeleteBrand = (index: number) => {
		const filteredBrands = mainBrand.filter((_, i) => i !== index);
		setMainBrand(filteredBrands);
		dispatch(getLogicalBrand(filteredBrands));
	};

	//for campaign search
	const handleSearchCampaign = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchCampaignText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleCampaignResult(true);
		}
		getCampaigns(text);
	};

	const getCampaigns = async (text: string) => {
		checkToken();
		await _axios
			.get(process.env.REACT_APP_CAMPAIGN_URL + "campaigns?s=" + text, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			})
			.then((res) => {
				const data = res.data.data;
				setCampaigns(data);
			});
	};

	const handleCampaignLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const limit: string = e.target.value.replace(/\D/g, "");

		const _mainCampaigns = mainCampaigns.map((campaign: LogicalObj) => {
			if (campaign.id === item.id) {
				return { ...campaign, limit: limit };
			}
			return campaign;
		});

		setMainCampaigns(_mainCampaigns);
		dispatch(getLogicalCampaign(_mainCampaigns));
	};

	const handleCampaign = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => {
		e.preventDefault();
		//setToggleCampaignResult((pVal) => !pVal)

		const _id = item.id;
		const _limit = item.limit;
		const _name = item.name;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
		};

		const _mainCampaigns = [...mainCampaigns];
		_mainCampaigns.push(_newObj);
		setMainCampaigns(_mainCampaigns);
		dispatch(getLogicalCampaign(_mainCampaigns));
		setSearchCampaignText("");
	};

	//for seller search
	const handleSearchSeller = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchSellerText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleSellerResult(true);
		}
		getSeller(text);
	};
	const getSeller = async (text: string) => {
		checkToken();
		await _axios
			.get(
				process.env.REACT_APP_BASE_URL +
					"shopmanager/shop/search/query?q=" +
					text,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			)
			.then((res) => {
				const data = res.data;
				setSellers(data);
			});
	};
	const handleSellerLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const limit: string = e.target.value.replace(/\D/g, "");

		const _mainSelles = mainSelles.map((seller: LogicalObj) => {
			if (seller.id === item.id) {
				return { ...seller, limit: limit };
			}
			return seller;
		});

		setMainSelles(_mainSelles);
		dispatch(getLogicalSeller(_mainSelles));
	};
	const handleSeller = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => {
		e.preventDefault();
		//setToggleSellerResult((pVal) => !pVal)
		// const sellerObj = {
		//     name: item.first_name,
		//     id: item.id
		// }
		// const _sellerList = [...sellerList]
		// _sellerList.push(sellerObj)
		// setSellerList(_sellerList)

		const _id = item.id;
		const _limit = "";
		const _name = item.name;
		const _avatar = item.avatar;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
			avatar: _avatar,
		};

		const _mainSelles = [...mainSelles];
		_mainSelles.push(_newObj);
		setMainSelles(_mainSelles);
		dispatch(getLogicalSeller(_mainSelles));
		setSearchSellerText("");
	};

	const handleDeleteSeller = (index: number) => {
		const filteredSellers = mainSelles.filter((_, i) => i !== index);
		setMainSelles(filteredSellers);
		dispatch(getLogicalSeller(filteredSellers));
	};

	const handleDeleteCampaign = (index: number) => {
		const filteredCampaign = mainCampaigns.filter((_, i) => i !== index);
		setMainCampaigns(filteredCampaign);
		dispatch(getLogicalCampaign(filteredCampaign));
	};

	const closeCategoryResult = () => {
		setToggleCategoryResult(false);
	};

	const closeBrandResult = () => {
		setToggleBrandResult(false);
	};

	const closeSellerResult = () => {
		setToggleSellerResult(false);
	};

	const closeCampaignResult = () => {
		setToggleCampaignResult(false);
	};

	// order by hanlders
	const handleOrderBy = (value: LogicalOrderTypes, key: LogicalOrderKeys) => {
		setOrderBy((prev) => ({
			...prev,
			[key]: value,
		}));

		switch (key) {
			case "categoryOrder":
				dispatch(setLogialCategoryOrder(value));
				break;
			case "brandOrder":
				dispatch(setLogialBrandOrder(value));
				break;
			case "sellerOrder":
				dispatch(setLogialSellerOrder(value));
				break;
			case "campaignOrder":
				dispatch(setLogialCampaignOrder(value));
				break;
		}
	};

	const handleIsFeatured = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsFeatured(e.target.checked);

		dispatch(setLogicalIsFeatured(e.target.checked));
	};

	const refCategoryResult = useDetectClickOutside({
		onTriggered: closeCategoryResult,
	});
	const refBrandResult = useDetectClickOutside({
		onTriggered: closeBrandResult,
	});
	const refSellerResult = useDetectClickOutside({
		onTriggered: closeSellerResult,
	});
	const refCampaignResult = useDetectClickOutside({
		onTriggered: closeCampaignResult,
	});

	const LogicalHead = React.memo(
		({
			title,
			orderKey,
		}: {
			title: string;
			orderKey: LogicalOrderKeys;
		}) => {
			return (
				<div className="pb-4">
					<p>{title}:</p>
					{/* order by selector */}
					<div className="flex items-center gap-2">
						<p className="text-[0.8rem]">Order by </p>
						<div className="border border-orange-500 rounded-md">
							<select
								className="w-full border-none focus:outline-none text-[0.8rem]"
								value={orderBy[orderKey]}
								onChange={(e) =>
									handleOrderBy(
										e.target.value as LogicalOrderTypes,
										orderKey
									)
								}>
								{orderArray.map((order) => (
									<option
										className="text-[0.8rem]"
										key={order}
										value={order}>
										{order}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			);
		}
	);

	return (
		<div className="mt-3">
			<div>
				<LogicalHead title="Category" orderKey="categoryOrder" />
				{/* is featured checkbox */}
				<div className="flex items-center gap-2">
					<input
						type="checkbox"
						className="w-4 h-4"
						checked={isFeatured}
						onChange={handleIsFeatured}
					/>
					<p className="text-[0.8rem]">Is Featured</p>
				</div>

				<div>
					{categoryList.length > 0 && (
						<div className="grid grid-cols-4 gap-2 border-b my-2">
							<div className="col-span-3 flex items-center text-sm tracking-wide overflow-hidden truncate">
								Category Id:
							</div>
							<div className="text-sm tracking-wide">limit:</div>
							{/* <div className='flex items-center justify-center text-sm tracking-wide'>order:</div> */}
						</div>
					)}
					{mainCategory.map((item, index) => {
						return (
							<div
								key={index}
								className="relative grid grid-cols-2 gap-2 bg-white shadow-lg mt-2 mb-4 p-2 rounded-md">
								<div className="ml-2 col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate">
									{item.name}
								</div>
								<div className="ml-2 col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate">
									Id: {item.id}
								</div>
								<div className="col-span-2 flex items-center gap-2">
									Limit:{" "}
									<input
										className="flex items-center text-center"
										type="text"
										value={item.limit}
										onChange={(e) =>
											handleCategoryLimit(e, item)
										}
									/>
								</div>

								{/* <div className='flex items-center justify-center'>{item.order}</div> */}
								<div
									onClick={() => handleDeleteCategory(index)}
									className="absolute -top-3 -right-3 bg-white shadow-lg rounded-full p-1 text-red-500 cursor-pointer">
									<CloseIcon />
								</div>
							</div>
						);
					})}
				</div>
				<div className="relative">
					<input
						value={serchTest}
						onChange={handleSearchCategory}
						type="text"
						placeholder="search..."
					/>
					{toggleCategoryResult && (
						<div
							ref={refCategoryResult}
							className="absolute left-0 right-0 top-10 bg-white z-10 shadow-lg p-2 divide-y max-h-72 min-h-auto overflow-y-scroll">
							{categories.map((item: any) => {
								return (
									<div
										onClick={() => handleCategory(item)}
										className="py-2 cursor-pointer"
										key={item.id}>
										<p>{item.name}</p>
										<p>/{item.slug}</p>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<div className="mt-4">
				<LogicalHead title="Brand" orderKey="brandOrder" />
				<div>
					{brandList.length > 0 && (
						<div className="grid grid-cols-4 gap-2 border-b my-2">
							<div className="col-span-3 flex items-center text-sm tracking-wide overflow-hidden truncate">
								Brands Id:
							</div>
							<div className="text-sm tracking-wide">limit:</div>
							{/* <div className='flex items-center justify-center text-sm tracking-wide'>order:</div> */}
						</div>
					)}
					{mainBrand.map((item, index) => {
						return (
							<div
								key={item.id}
								className="relative grid grid-cols-2 gap-2 bg-white shadow-lg mb-2 p-2 rounded-md">
								<div className="ml-2 col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate">
									{item.name}
								</div>
								<div className="col-span-2 flex items-center gap-2">
									Limit:{" "}
									<input
										className="flex items-center text-center"
										type="text"
										value={item.limit}
										onChange={(e) =>
											handleBrandLimit(e, item)
										}
									/>
								</div>
								{/* <div className='flex items-center justify-center'>{item.order}</div> */}

								<div
									onClick={() => handleDeleteBrand(index)}
									className="absolute -top-3 -right-3 bg-white shadow-lg rounded-full p-1 text-red-500 cursor-pointer">
									<CloseIcon />
								</div>
							</div>
						);
					})}
				</div>
				<div className="relative">
					<input
						value={serachBrandText}
						onChange={handleSearchBrand}
						type="text"
						placeholder="search..."
					/>
					{toggleBrandResult && (
						<div
							ref={refBrandResult}
							className="absolute left-0 right-0 top-10 bg-white z-10 shadow-lg p-2 divide-y max-h-72 min-h-auto overflow-y-scroll">
							{brands.map((item: any) => {
								return (
									<div
										onClick={() => handleBrand(item)}
										className="py-2 cursor-pointer"
										key={item.id}>
										{item.name}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>

			{/* <div className='mt-4'>
            <LogicalHead 
                title='Campaigns'
                orderKey='campaignOrder'
            />
            <div>
                {
                    campaignList.length > 0 &&
                    <div className='grid grid-cols-4 gap-2 border-b my-2'>
                        <div className='col-span-3 flex items-center text-sm tracking-wide overflow-hidden truncate'>Campaign Id:</div>
                        
                    </div>
                }
                {
                    mainCampaigns.map((item, index) =>{
                        return (
                            <div key={index} className='relative grid grid-cols-2 gap-2 bg-white shadow-lg mb-2 p-2 rounded-md'>
                                <div className='col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate text-center font-light'>{item.name} (ID: {item.id})</div>
                                <div className='col-span-2 flex items-center gap-2 text-sm font-light tracking-wide'>Limit: <input className='flex items-center text-center' type="text" value={item.limit} onChange={(e) => handleCampaignLimit(e, item)}/></div>
                                
                                
                                <div onClick={()=> handleDeleteCampaign(index)} className='absolute -top-3 -right-3 bg-white shadow-lg rounded-full p-1 text-red-500 cursor-pointer'>
                                    <CloseIcon />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='relative'>
                <input value={searchCampaignText} onChange={handleSearchCampaign} type="text" placeholder='search...' />
                { 
                    toggleCampaignResult &&
                    <div ref={refCampaignResult} className='absolute left-0 right-0 top-10 bg-white z-10 shadow-lg p-2 divide-y max-h-72 min-h-auto overflow-y-scroll'>
                        {
                            campaigns.map((item: any) => {
                                return (
                                    <div onClick={(e) => handleCampaign(e, item)} className='py-2 cursor-pointer' key={item.id}>{item.name}</div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>

        <div className='mt-4'>
            <LogicalHead 
                title='Sellers'
                orderKey='sellerOrder'
            />
            <div>
                {
                    sellerList.length > 0 &&
                    <div className='grid grid-cols-4 gap-2 border-b my-2'>
                        <div className='col-span-3 flex items-center text-sm tracking-wide overflow-hidden truncate'>Sellers Id:</div>
                        
                    </div>
                }
                {
                    mainSelles.map((item, index) =>{
                        return (
                            <div key={index} className='relative grid grid-cols-2 gap-2 bg-white shadow-lg mb-2 p-2 rounded-md'>
                                <div className='col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate gap-4'>
                                    {
                                        item?.avatar ? <img className='w-10 h-10 rounded-full' src={process.env.REACT_APP_SELLER_IMAGE_URL + (item.avatar || '')} alt="" /> :
                                        <img className='w-10 h-10 rounded-full' src="/img/image-placeholder.jpg" alt="" />
                                    }
                                    <div className='text-sm font-light tracking-wide'>
                                        {item.name}(ID: {item.id})
                                    </div>
                                </div>
                                <div className='col-span-2 flex items-center gap-2 text-sm font-light tracking-wide'>Limit: <input className='flex items-center text-center' type="text" value={item.limit} onChange={(e) => handleSellerLimit(e, item)}/></div>
                                
                                
                                <div onClick={()=> handleDeleteSeller(index)} className='absolute -top-3 -right-3 bg-white shadow-lg rounded-full p-1 text-red-500 cursor-pointer'>
                                    <CloseIcon />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='relative'>
                <input value={serachSellerText} onChange={handleSearchSeller} type="text" placeholder='search...' />
                { 
                    toggleSellerResult &&
                    <div ref={refSellerResult} className='absolute left-0 right-0 top-10 bg-white z-10 shadow-lg p-2 divide-y max-h-72 min-h-auto overflow-y-scroll'>
                        {
                            sellers.map((item: any) => {
                                return (
                                    <div onClick={(e) => handleSeller(e, item)} className='py-2 cursor-pointer flex items-center gap-4' key={item.id}>
                                        {
                                            item?.avatar ? <img className='w-10 h-10 rounded-full' src={process.env.REACT_APP_SELLER_IMAGE_URL + (item.avatar || '')} alt="" /> :
                                            <img className='w-10 h-10 rounded-full' src="/img/image-placeholder.jpg" alt="" />
                                        }
                                        <div className='text-sm font-light tracking-wide'>
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div> */}
		</div>
	);
};

export const LogicalBuilderV2 = ({
	logical,
	onUpdate,
}: {
	logical?: Logical;
	onUpdate: (logical: Logical) => void;
}) => {
	const choosenCategories = logical?.category || [];
	const choosenBrands = logical?.brand || [];
	const choosenSellers = logical?.seller || [];
	const choosenCampaigns = logical?.campaign || [];

	const logicalOrderByGlobal: LogicalOrderObjects = {
		categoryOrder: logical?.categoryOrder || "desc",
		brandOrder: logical?.brandOrder || "desc",
		sellerOrder: logical?.sellerOrder || "desc",
		campaignOrder: logical?.campaignOrder || "desc",
	};

	//for category search
	const [mainCategory, setMainCategory] =
		useState<LogicalObj[]>(choosenCategories);
	const [serchTest, setSearchText] = useState("");
	const [toggleCategoryResult, setToggleCategoryResult] = useState(false);
	const [categories, setCategories] = useState([]);
	const [categoryList, setCategoryList] = useState<CategoryObj[]>([]);

	//for brand search
	const [mainBrand, setMainBrand] = useState<LogicalObj[]>(choosenBrands);
	const [serachBrandText, setSearchBrandText] = useState("");
	const [toggleBrandResult, setToggleBrandResult] = useState(false);
	const [brands, setBrands] = useState([]);
	const [brandList, setBrandList] = useState<CategoryObj[]>([]);

	//for seller search
	const [mainSelles, setMainSelles] = useState<LogicalObj[]>(choosenSellers);
	const [serachSellerText, setSearchSellerText] = useState("");
	const [toggleSellerResult, setToggleSellerResult] = useState(false);
	const [sellers, setSellers] = useState([]);
	const [sellerList, setSellerList] = useState<LogicalObj[]>([]);

	//for campaign search
	const [mainCampaigns, setMainCampaigns] =
		useState<LogicalObj[]>(choosenCampaigns);
	const [searchCampaignText, setSearchCampaignText] = useState("");
	const [toggleCampaignResult, setToggleCampaignResult] = useState(false);
	const [campaigns, setCampaigns] = useState([]);
	const [campaignList, setCampaignList] = useState<CategoryObj[]>([]);

	// for order
	const [orderBy, setOrderBy] =
		useState<LogicalOrderObjects>(logicalOrderByGlobal);

	const handleUpadte = () => {
		const logicalObj: Logical = {
			category: mainCategory,
			brand: mainBrand,
			seller: mainSelles,
			campaign: mainCampaigns,
			categoryOrder: orderBy.categoryOrder,
			brandOrder: orderBy.brandOrder,
			sellerOrder: orderBy.sellerOrder,
			campaignOrder: orderBy.campaignOrder,
		};
		onUpdate(logicalObj);
	};

	//for category search
	const handleSearchCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleCategoryResult(true);
		}
		getCategory(text);
	};
	const getCategory = async (text: string) => {
		checkToken();
		getCategoryList({
			search: text,
			status: "active",
		}).then((data) => {
			setCategories(data.data as any);
		});
	};
	const handleCategoryLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const categoryLimit: string = e.target.value.replace(/\D/g, "");

		const _mainCategory = mainCategory.map((category: LogicalObj) => {
			if (category.id === item.id) {
				return { ...category, limit: categoryLimit };
			}
			return category;
		});

		setMainCategory(_mainCategory);
	};
	const handleCategory = (item: LogicalObj) => {
		setToggleCategoryResult(false);
		// const categoryObj = {
		//     id: item.id,
		//     name: item.name,
		//     limit: categoryLimit,
		//     order: categoryOrder
		// }
		// const _categoryList = [...categoryList]
		// _categoryList.push(categoryObj)
		// setCategoryList(_categoryList)

		const _id = item.id;
		const _limit = item.limit;
		const _name = item.name;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
		};

		const _mainCategory = [...mainCategory];
		_mainCategory.push(_newObj);
		setMainCategory(_mainCategory);
		setSearchText("");
	};
	const handleDeleteCategory = (index: number) => {
		const filteredCategory = mainCategory.filter((_, i) => i !== index);
		setMainCategory(filteredCategory);
	};

	//for brand search
	const handleSearchBrand = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchBrandText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleBrandResult(true);
		}
		getBrand(text);
	};
	const getBrand = async (text: string) => {
		checkToken();
		getBrandList({
			search: text,
			status: "active",
		}).then((data) => {
			setBrands(data.data as any);
		});
	};
	const handleBrandLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const brandLimit: string = e.target.value.replace(/\D/g, "");
		const _mainBrand = mainBrand.map((brand: LogicalObj) => {
			if (brand.id === item.id) {
				return { ...brand, limit: brandLimit };
			}
			return brand;
		});
		setMainBrand(_mainBrand);
	};
	const handleBrand = (item: any) => {
		setToggleBrandResult((pVal) => !pVal);
		// const brandObj = {
		//     id: item.id,
		//     name: item.name,
		//     limit: categoryLimit,
		//     order: categoryOrder
		// }
		// const _brandList = [...brandList]
		// _brandList.push(brandObj)
		// setBrandList(_brandList)

		const _id = item.id;
		const _limit = item.limit;
		const _name = item.name;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
		};

		const _mainBrand = [...mainBrand];
		_mainBrand.push(_newObj);
		setMainBrand(_mainBrand);
		setSearchBrandText("");
	};
	const handleDeleteBrand = (index: number) => {
		const filteredBrands = mainBrand.filter((_, i) => i !== index);
		setMainBrand(filteredBrands);
	};

	//for campaign search
	const handleSearchCampaign = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchCampaignText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleCampaignResult(true);
		}
		getCampaigns(text);
	};

	const getCampaigns = async (text: string) => {
		checkToken();
		await _axios
			.get(process.env.REACT_APP_CAMPAIGN_URL + "campaigns?s=" + text, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			})
			.then((res) => {
				const data = res.data.data;
				setCampaigns(data);
			});
	};

	const handleCampaignLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const limit: string = e.target.value.replace(/\D/g, "");

		const _mainCampaigns = mainCampaigns.map((campaign: LogicalObj) => {
			if (campaign.id === item.id) {
				return { ...campaign, limit: limit };
			}
			return campaign;
		});

		setMainCampaigns(_mainCampaigns);
	};

	const handleCampaign = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => {
		e.preventDefault();
		//setToggleCampaignResult((pVal) => !pVal)

		const _id = item.id;
		const _limit = item.limit;
		const _name = item.name;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
		};

		const _mainCampaigns = [...mainCampaigns];
		_mainCampaigns.push(_newObj);
		setMainCampaigns(_mainCampaigns);
		setSearchCampaignText("");
	};

	//for seller search
	const handleSearchSeller = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchSellerText(e.target.value);
		const text: string = e.target.value;
		if (text.length > 0) {
			setToggleSellerResult(true);
		}
		getSeller(text);
	};
	const getSeller = async (text: string) => {
		checkToken();
		await _axios
			.get(
				process.env.REACT_APP_BASE_URL +
					"shopmanager/shop/search/query?q=" +
					text,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			)
			.then((res) => {
				const data = res.data;
				setSellers(data);
			});
	};
	const handleSellerLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		item: LogicalObj
	) => {
		const limit: string = e.target.value.replace(/\D/g, "");

		const _mainSelles = mainSelles.map((seller: LogicalObj) => {
			if (seller.id === item.id) {
				return { ...seller, limit: limit };
			}
			return seller;
		});

		setMainSelles(_mainSelles);
	};
	const handleSeller = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => {
		e.preventDefault();
		//setToggleSellerResult((pVal) => !pVal)
		// const sellerObj = {
		//     name: item.first_name,
		//     id: item.id
		// }
		// const _sellerList = [...sellerList]
		// _sellerList.push(sellerObj)
		// setSellerList(_sellerList)

		const _id = item.id;
		const _limit = "";
		const _name = item.name;
		const _avatar = item.avatar;

		const _newObj: LogicalObj = {
			id: _id,
			limit: _limit,
			name: _name,
			avatar: _avatar,
		};

		const _mainSelles = [...mainSelles];
		_mainSelles.push(_newObj);
		setMainSelles(_mainSelles);
		setSearchSellerText("");
	};

	const handleDeleteSeller = (index: number) => {
		const filteredSellers = mainSelles.filter((_, i) => i !== index);
		setMainSelles(filteredSellers);
	};

	const handleDeleteCampaign = (index: number) => {
		const filteredCampaign = mainCampaigns.filter((_, i) => i !== index);
		setMainCampaigns(filteredCampaign);
	};

	const closeCategoryResult = () => {
		setToggleCategoryResult(false);
	};

	const closeBrandResult = () => {
		setToggleBrandResult(false);
	};

	const closeSellerResult = () => {
		setToggleSellerResult(false);
	};

	const closeCampaignResult = () => {
		setToggleCampaignResult(false);
	};

	// order by hanlders
	const handleOrderBy = (value: LogicalOrderTypes, key: LogicalOrderKeys) => {
		setOrderBy((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const refCategoryResult = useDetectClickOutside({
		onTriggered: closeCategoryResult,
	});
	const refBrandResult = useDetectClickOutside({
		onTriggered: closeBrandResult,
	});
	const refSellerResult = useDetectClickOutside({
		onTriggered: closeSellerResult,
	});
	const refCampaignResult = useDetectClickOutside({
		onTriggered: closeCampaignResult,
	});

	const LogicalHead = React.memo(
		({
			title,
			orderKey,
		}: {
			title: string;
			orderKey: LogicalOrderKeys;
		}) => {
			return (
				<div className="pb-4">
					<p>{title}:</p>
					{/* order by selector */}
					<div className="flex items-center gap-2">
						<p className="text-[0.8rem]">Order by </p>
						<div className="border border-orange-500 rounded-md">
							<select
								className="w-full border-none focus:outline-none text-[0.8rem]"
								value={orderBy[orderKey]}
								onChange={(e) =>
									handleOrderBy(
										e.target.value as LogicalOrderTypes,
										orderKey
									)
								}>
								{orderArray.map((order) => (
									<option
										className="text-[0.8rem]"
										key={order}
										value={order}>
										{order}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			);
		}
	);

	return (
		<div className="mt-3">
			<div>
				<LogicalHead title="Category" orderKey="categoryOrder" />
				<div>
					{categoryList.length > 0 && (
						<div className="grid grid-cols-4 gap-2 border-b my-2">
							<div className="col-span-3 flex items-center text-sm tracking-wide overflow-hidden truncate">
								Category Id:
							</div>
							<div className="text-sm tracking-wide">limit:</div>
							{/* <div className='flex items-center justify-center text-sm tracking-wide'>order:</div> */}
						</div>
					)}
					{mainCategory.map((item, index) => {
						return (
							<div
								key={index}
								className="relative grid grid-cols-2 gap-2 bg-white shadow-lg mt-2 mb-4 p-2 rounded-md">
								<div className="ml-2 col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate">
									{item.name}
								</div>
								<div className="col-span-2 flex items-center gap-2">
									Limit:{" "}
									<input
										className="flex items-center text-center"
										type="text"
										value={item.limit}
										onChange={(e) =>
											handleCategoryLimit(e, item)
										}
									/>
								</div>

								{/* <div className='flex items-center justify-center'>{item.order}</div> */}
								<div
									onClick={() => handleDeleteCategory(index)}
									className="absolute -top-3 -right-3 bg-white shadow-lg rounded-full p-1 text-red-500 cursor-pointer">
									<CloseIcon />
								</div>
							</div>
						);
					})}
				</div>
				<div className="relative">
					<input
						value={serchTest}
						onChange={handleSearchCategory}
						type="text"
						placeholder="search..."
					/>
					{toggleCategoryResult && (
						<div
							ref={refCategoryResult}
							className="absolute left-0 right-0 top-10 bg-white z-10 shadow-lg p-2 divide-y max-h-72 min-h-auto overflow-y-scroll">
							{categories.map((item: any) => {
								return (
									<div
										onClick={() => handleCategory(item)}
										className="py-2 cursor-pointer"
										key={item.id}>
										{item.name}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<div className="mt-4">
				<LogicalHead title="Brand" orderKey="brandOrder" />
				<div>
					{brandList.length > 0 && (
						<div className="grid grid-cols-4 gap-2 border-b my-2">
							<div className="col-span-3 flex items-center text-sm tracking-wide overflow-hidden truncate">
								Brands Id:
							</div>
							<div className="text-sm tracking-wide">limit:</div>
							{/* <div className='flex items-center justify-center text-sm tracking-wide'>order:</div> */}
						</div>
					)}
					{mainBrand.map((item, index) => {
						return (
							<div
								key={item.id}
								className="relative grid grid-cols-2 gap-2 bg-white shadow-lg mb-2 p-2 rounded-md">
								<div className="ml-2 col-span-2 flex items-center text-sm tracking-wide overflow-hidden truncate">
									{item.name}
								</div>
								<div className="col-span-2 flex items-center gap-2">
									Limit:{" "}
									<input
										className="flex items-center text-center"
										type="text"
										value={item.limit}
										onChange={(e) =>
											handleBrandLimit(e, item)
										}
									/>
								</div>
								{/* <div className='flex items-center justify-center'>{item.order}</div> */}

								<div
									onClick={() => handleDeleteBrand(index)}
									className="absolute -top-3 -right-3 bg-white shadow-lg rounded-full p-1 text-red-500 cursor-pointer">
									<CloseIcon />
								</div>
							</div>
						);
					})}
				</div>
				<div className="relative">
					<input
						value={serachBrandText}
						onChange={handleSearchBrand}
						type="text"
						placeholder="search..."
					/>
					{toggleBrandResult && (
						<div
							ref={refBrandResult}
							className="absolute left-0 right-0 top-10 bg-white z-10 shadow-lg p-2 divide-y max-h-72 min-h-auto overflow-y-scroll">
							{brands.map((item: any) => {
								return (
									<div
										onClick={() => handleBrand(item)}
										className="py-2 cursor-pointer"
										key={item.id}>
										{item.name}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>

			<div className="mt-4">
				<button
					type="button"
					className="update_button"
					onClick={handleUpadte}>
					Update
				</button>
			</div>
		</div>
	);
};

export default LogicalBuilder;
