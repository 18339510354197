import { CSSProperties, useState } from 'react';
import classes from './BrandsFilterSort.module.css'
import { useDispatch } from 'react-redux';
import { setGlobalPopupElement } from '../../features/popupSlice';
import PopupWrapper from '../PopupWrapper';
import SortList from '../ListSort';
import { getBrandList } from '../../utils/requests';
import { CategoryItem } from '../../types/category';

interface Props {
    className?: string;
    style?: CSSProperties;

    category?: { id: number, name: string, slug: string };
    brands?: CategoryItem [];
    rest?: boolean;

    onUpdate?: (data: CategoryItem [], rest: boolean) => void;
}

const BrandsFilterSort = ({
    className,
    style,

    category,
    brands = [],
    rest = false,

    onUpdate = ()=> {}
}: Props) => {
    const dispatch = useDispatch();
    
    const classNames = [classes.BrandsFilterSort, className];
    
    const PopupEl = () => {
        const [restActive, setRestActive] = useState(rest);

        return (
            <PopupWrapper>
                <div className="bg-white pb-4 rounded-md">
                    <SortList<CategoryItem>
                        data={brands}
                        renderElement={item => (
                            <div
                                className='flex items-center gap-2'
                            >
                                <p>{item?.name}</p>
                            </div>
                        )}
                        updateData={(data) => {
                            onUpdate(data, restActive);
                            
                            dispatch(setGlobalPopupElement(null));
                        }}
                        onSearch={(value) => {
                            return new Promise((resolve, reject) => {
                                getBrandList({
                                    search: value,
                                    status: 'active',
                                    category: category?.slug
                                }).then(res => {
                                    resolve(res.data || []);
                                }).catch(err => {
                                    reject(err);
                                })
                            })
                        }}
                    />

                    <div className="flex gap-2 items-center px-4">
                        <input
                            id='rest-id-1323434'
                            type='checkbox'
                            className='!w-auto'
                            checked={restActive}
                            onChange={e => setRestActive(e.target.checked)}
                        />
                        <label htmlFor={'rest-id-1323434'} className='mainSliderForm__label'>
                            Show rest of all brands
                        </label>
                    </div>
                </div>
            </PopupWrapper>
        )
    }

    const handleSort = () => {
        dispatch(setGlobalPopupElement(<PopupEl />))
    }
    
    return (
        <div className={classNames.join(' ')} style={style}>
            <button className={classes.button}
                onClick={handleSort}
            >
                Sort Brand Filters
            </button>
        </div>
    )
}

export default BrandsFilterSort