import { useState } from "react";
import { CustomFormProps } from ".";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../SettingFormItems";
import classes from "./CWForm.module.css";

export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

type SettingsNames =
	| "addToCart"
	| "maxCODAmount"
	| "cardWidth"
	| "cardHeight"
	| "buyNow"
	| "headerColor"
	| "trackOrderBtn"
	| "fullHeader"
	| "fbPageId";

const productSettings: SettingItem<SettingsNames>[] = [
	// {
	// 	name: 'Add To Cart Button',
	// 	type: 'checkbox',
	// 	key: 'addToCart',
	// },
	// {
	// 	name: "Buy Now Button",
	// 	type: "checkbox",
	// 	key: "buyNow",
	// },
	// {
	// 	name: "Card Width",
	// 	type: "number",
	// 	key: "cardWidth",
	// },
	// {
	// 	name: "Card Height",
	// 	type: "number",
	// 	key: "cardHeight",
	// },
	{
		name: "Max COD Amount",
		type: "number",
		key: "maxCODAmount",
	},
];

const headerSettings: SettingItem<SettingsNames>[] = [
	// {
	// 	name: "Header Color",
	// 	type: "color",
	// 	key: "headerColor",
	// },
	// {
	// 	name: "Track Order Button",
	// 	type: "checkbox",
	// 	key: "trackOrderBtn",
	// },
	// {
	// 	name: "is Full Header",
	// 	type: "checkbox",
	// 	key: "fullHeader",
	// },
	// {
	// 	name: "Facebook Page Id",
	// 	type: "text",
	// 	key: "fbPageId",
	// },
];

const PageSettingsForm = ({
	formItems,
	customForm = false,
	name = "",
	onSubmit = () => {},
	title,
	className,
	style,
}: CustomFormProps) => {
	const [settings, setSettings] = useState<SettingItems<SettingsNames>>(
		formItems as any
	);

	const classNames = [classes.CWForm, className];

	const handleSubmit = () => {
		const data: any = {
			...settings,
		};

		onSubmit(data as any);
	};

	return (
		<div className={classNames.join(" ")} style={style}>
			<form
				className="mainSliderForm__container"
				onSubmit={(e) => {
					e.preventDefault();
				}}>
				<h1 className="title capitalize">{title}</h1>

				<SettingFormItems
					title="Page Settings"
					settings={settings}
					settingItems={productSettings}
					onChange={(key, value) => {
						setSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/>

				{/* <SettingFormItems
					title="Header Settings"
					settings={settings}
					settingItems={headerSettings}
					onChange={(key, value) => {
						setSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/> */}

				<div>
					<button
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
						className="mainSliderForm__btn">
						save
					</button>
				</div>
			</form>
		</div>
	);
};

export default PageSettingsForm;
