import EditIcon from "./icons/EditIcon";

export const ImageContainerEditButton = ({
  handleImageSelector,
  index,
  imageUrl,
  title,
}: {
  handleImageSelector: (index?: number) => void;
  index?: number;
  imageUrl: string;
  title?: string;
}) => (
  <div className="relative w-full h-full">
    <img
      className="rounded-md mb-1 w-full h-full object-contain"
      src={imageUrl}
      alt={title || "img"}
    />
    <div className="absolute flex justify-center items-center top-0 right-0 w-full h-full bg-black/50 transition-opacity duration-500 opacity-0 hover:opacity-100">
      <button
        onClick={(e) => {
          e.preventDefault();
          handleImageSelector(index);
        }}
        className="p-2 [&>*]:stroke-white"
      >
        <EditIcon />
      </button>
    </div>
  </div>
);
