import axios from "axios";
import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useDispatch } from "react-redux";
import { getCustomProducts } from "../../../features/logicBuilder/logicBuilder";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { adminTokenCookieName, envs } from "../../../utils/constants";
import { Widget } from "../../../widgetList/widgetList";
import CustomProductPopup from "../../CustomProductPopup";

interface Props {
	choosenItem: Widget;
	sourceType: string;
}

const CustomBuilder: FC<Props> = ({ sourceType, choosenItem }) => {
	const choosenCustomProducts = choosenItem?.data?.setting?.custom || [];

	const ids: any[] = choosenItem?.data?.setting?.custom || [];
	const [text, setText] = useState<string>("");
	const [products, setProdcuts] = useState<any[]>([]);
	// const [allProducts, setAllProducts] = useState<any[]>()
	const [choosenProducts, setChoosenProducts] = useState<any[]>([]);
	const [show, setShow] = useState<boolean>(true);
	const [productIds, setProductIds] = useState<any[]>([]);

	const dispatch = useDispatch();

	useEffect(() => {
		//getAllProducts(ids)
		setChoosenProducts(choosenCustomProducts);
		setProductIds(ids);
		// dispatch(clearSetting());
		dispatch(getCustomProducts(choosenCustomProducts));
	}, [choosenItem]);

	useEffect(() => {
		const abortController = new AbortController();

		const apiUrl = envs.frontendApiUrl?.replace("api/v1", "api/v2");

		const timeout = setTimeout(() => {
			axios
				.get(apiUrl + "product/storefront/search?search=" + text, {
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
					signal: abortController.signal,
				})
				.then((res) => {
					setProdcuts(res.data?.data);
					setShow(true);
				});
		}, 300);

		return () => {
			clearTimeout(timeout);

			try {
				abortController.signal.throwIfAborted();

				if (abortController.signal.aborted === false)
					abortController.abort();
			} catch (error) {}
		};
	}, [text]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		const _text: string = e.target.value;
		// getProducts(_text.trim());
	};

	// const getProducts = async (_text: string) => {
	// 	checkToken();
	// 	await _axios
	// 		.get(envs.frontendApiUrl + "product/storefront?search=" + _text, {
	// 			headers: {
	// 				Authorization: `Bearer ${Cookies.get(
	// 					adminTokenCookieName
	// 				)}`,
	// 			},
	// 		})
	// 		.then((res) => {
	// 			setProdcuts(res.data?.data);
	// 			setShow(true);
	// 		});
	// };

	// const getAllProducts = async(ids: any) => {
	//   checkToken()
	//     const postData = {
	//         "productIds": ids,
	//     }
	//     const res = await _axios.post(`${process.env.REACT_APP_BASE_URL}catalog/product/frontend`, postData, { headers: {
	//       'Content-Type': 'application/json',
	//       "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
	//     }})
	//     setChoosenProducts(res.data)
	// }

	const handleChoosenProduct = (product: any) => {
		const _choosenProducts = [...choosenProducts];
		_choosenProducts.push(product);
		setChoosenProducts(_choosenProducts);

		// const ids: number[] = _choosenProducts.map(item => {
		//   return item.id
		// })

		// setProductIds(ids)

		// dispatch(getChoosenProductsForCustom(_choosenProducts))
		// dispatch(getCustomProducts(ids))
		dispatch(getCustomProducts(_choosenProducts));
		setText("");
	};

	const hideProductList = () => {
		setShow(false);
	};

	const handleRemoveProductId = (index: number) => {
		// const filteredProductIds = productIds.filter((_,i)=> i !== index)
		// setProductIds(filteredProductIds)
		const filteredProducts = choosenProducts.filter((_, i) => i !== index);
		setChoosenProducts(filteredProducts);
		dispatch(getCustomProducts(filteredProducts));
	};

	const ref = useDetectClickOutside({ onTriggered: hideProductList });

	return (
		<div className="mt-2">
			<div>
				{
					<div className="w-full my-4">
						{/* {
              choosenProducts.map((item: Widget, index: number) => {
                return (
                  <div key={index} className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
                    <div className='capitalize text-xs font-light tracking-wide p-3'>
                      <div className='mb-2'><span className='font-bold'>Product: </span>{item?.name}</div>
                      <div><span className='font-bold'>Sku: </span>{item?.monarchmart_sku}</div>
                    </div>
                    <div onClick={()=>handleRemoveProductId(index)} className='absolute -top-3 -right-3 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg'>
                      <CloseIcon />
                    </div>
                  </div>
                )
              })
            } */}
						<div className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
							<div className="capitalize text-xs font-light tracking-wide p-3">
								{choosenProducts.length > 0 ? (
									<p>
										Total products: {choosenProducts.length}
									</p>
								) : (
									<p>No products added</p>
								)}
							</div>
						</div>
					</div>
				}
			</div>
			<div>
				<button
					className="text-gray-500 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full"
					onClick={(e) => {
						e.preventDefault();

						dispatch(
							setGlobalPopupElement(
								<CustomProductPopup
									initialProducts={choosenProducts}
									onSetProducts={(products) => {
										setChoosenProducts(products);
									}}
								/>
							)
						);
					}}>
					Manage Products
				</button>
				{/* <input value={text} type="text" placeholder='search...'  onChange={handleOnChange}/> */}
				<div>
					{products?.length > 0 && show && (
						<div
							className="bg-white px-3 shadow-lg divide-y max-h-96 min-h-fit overflow-y-scroll"
							ref={ref}>
							{products?.map((product) => {
								return (
									<div key={product.id}>
										<div
											className="flex flex-col items-center justify-between cursor-pointer py-2"
											onClick={() =>
												handleChoosenProduct(product)
											}>
											<div className="text-xs font-light w-full">
												<span className="font-bold">
													Product:
												</span>{" "}
												{product?.name}
											</div>
											<div className="text-xs font-light w-full">
												<span className="font-bold">
													Sku:
												</span>{" "}
												{product?.monarchmart_sku}
											</div>
											<div className="text-xs font-light flex items-center w-full justify-start">
												<span className="font-bold">
													Price:
												</span>
												{product.discount_price ? (
													<div>
														<span className="text-gray-400 line-through mr-3">
															&#2547;
															{
																product?.purchase_price
															}
														</span>
														<span className="text-red-500">
															&#2547;
															{
																product?.discount_price
															}
														</span>
													</div>
												) : (
													<div className="text-red-500">
														{product.purchase_price}
													</div>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const CustomBuilderV2 = ({
	custom = [],
	onUpdate,
}: {
	custom?: number[];
	onUpdate: (custom: number[]) => void;
}) => {
	const dispatch = useDispatch();
	const [choosenProducts, setChoosenProducts] = useState<any[]>(custom);

	const handleUpadte = () => {
		onUpdate(choosenProducts);
	};

	return (
		<div className="mt-2">
			<div>
				{
					<div className="w-full my-4">
						<div className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
							<div className="capitalize text-xs font-light tracking-wide p-3">
								{choosenProducts.length > 0 ? (
									<p>
										Total products: {choosenProducts.length}
									</p>
								) : (
									<p>No products added</p>
								)}
							</div>
						</div>
					</div>
				}
			</div>
			<div>
				<button
					className="text-gray-500 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full"
					onClick={(e) => {
						e.preventDefault();

						dispatch(
							setGlobalPopupElement(
								<CustomProductPopup
									initialProducts={choosenProducts}
									onSetProducts={(products) => {
										setChoosenProducts(products);
									}}
								/>
							)
						);
					}}>
					Manage Products
				</button>
				{/* <input value={text} type="text" placeholder='search...'  onChange={handleOnChange}/> */}
			</div>

			<div className="mt-4">
				<button
					type="button"
					className="update_button"
					onClick={handleUpadte}>
					Update
				</button>
			</div>
		</div>
	);
};

export default CustomBuilder;
