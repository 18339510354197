import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useDispatch, useSelector } from "react-redux";
import { checkToken } from "../../../App";
import { _axios } from "../../../api";
import { RootState } from "../../../app/store";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { adminTokenCookieName, envs } from "../../../utils/constants";
import { PageCategory, Widget } from "../../../widgetList/widgetList";
import CampaignSelector from "../../CampaignSelector";
import ImagePopUpV2 from "../../ImagePopUpV2";
import { ImageSelectorV2 } from "../../ImageSelector";
import { ImageContainerEditButton } from "../../components";
import CloseIcon from "../../icons/CloseIcon";
import LinkForm, { LinkData } from "../LinkForm";
import ProductsDefaultSettings from "../ProductsDefaultSettings";
import TopPanel, { Panel } from "../TopPanel";

interface Props {
	choosenItem: any; // type: Widget
	onUpdate: (w: Widget) => void;
}

const FormForBestSellingProdcuts: FC<Props> = ({ choosenItem, onUpdate }) => {
	const dispatch = useDispatch();
	const choosenAllImages = useSelector(
		(state: RootState) => state.showImagePopUp.images
	);
	const customProducts = useSelector(
		(state: RootState) => state?.logicBuilder?.setting?.custom
	);

	const [active, setActive] = useState<boolean>(choosenItem.active);
	const [text, setText] = useState("");
	const [featuredImage, setFeatiredImage] = useState("");
	const [title, setTitle] = useState("");
	const [link, setLink] = useState<LinkData>({} as LinkData);
	const [fetcturedImagelinkTarget, setFetcturedImagelinkTarget] =
		useState("");
	const [products, setProducts] = useState<any[]>([]);
	const [choosenProducts, setChoosenProducts] =
		useState<any[]>(customProducts);
	const [show, setShow] = useState<boolean>(true);

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	const [panel, setPanel] = useState<Panel>("main");
	const [settings, setSettings] = useState<any>(
		choosenItem?.data?.settings as any
	);

	useEffect(() => {
		setFeatiredImage(
			choosenItem?.data?.bestSellingProducts?.featuredImage?.image || ""
		);
		setLink(
			choosenItem?.data?.bestSellingProducts?.featuredImage?.url ||
				({} as LinkData)
		);
		setTitle(
			choosenItem?.data?.bestSellingProducts?.featuredImage?.title || ""
		);
		setFetcturedImagelinkTarget(
			choosenItem?.data?.bestSellingProducts?.featuredImage?.target || ""
		);
		setChoosenProducts(
			choosenItem?.data?.bestSellingProducts?.products || []
		);
	}, [choosenItem]);

	// useEffect(()=>{
	//     choosenAllImages.map((item: any) =>{
	//         return (
	//             setFeatiredImage(item.path)
	//         )
	//     })
	// },[choosenAllImages])

	const handleImageSelector = () => {
		dispatch(
			setGlobalPopupElement(
				<ImagePopUpV2
					single={true}
					onUpdate={(images) => {
						const arrayImages = images.map((item: any) => {
							return {
								image: item.path,
								title: item.title,
								link: {} as LinkData,
							};
						});

						// setImages(arrayImages)
						const item = arrayImages[0];

						setFeatiredImage(item.image);

						dispatch(setGlobalPopupElement(null));
					}}
					onClose={() => {
						dispatch(setGlobalPopupElement(null));
					}}
				/>
			)
		);
	};

	const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleLinkData = (data: LinkData) => {
		setLink(data);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		const _text: string = e.target.value;
		console.log("_text =>", _text);
		getProducts(_text.trim());
	};

	const getProducts = async (_text: string) => {
		checkToken();

		const apiUrl = envs.frontendApiUrl?.replace("api/v1", "api/v2");

		await _axios
			.get(apiUrl + "product/storefront/search?search=" + _text, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			})
			.then((res) => {
				setProducts(res.data?.data);
				setShow(true);
			});
	};

	const hideProductList = () => {
		setShow(false);
	};

	const handleChoosenProduct = (product: any) => {
		const _choosenProducts = [...choosenProducts];
		_choosenProducts.push(product);
		setChoosenProducts(_choosenProducts);
		setText("");
	};

	const handleRemoveProductId = (index: number) => {
		const filteredProducts = choosenProducts.filter((_, i) => i !== index);
		setChoosenProducts(filteredProducts);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const widgetObj: any = {
			name: "best-selling",
			active: active,
			data: {
				bestSellingProducts: {
					products: choosenProducts,
					featuredImage: {
						image: featuredImage,
						title: title,
						url: link,
						target: fetcturedImagelinkTarget,
					},
				},
				settings: settings,
				selectedCampaign:
					selectedCampaign ||
					choosenItem?.data?.selectedCampaign ||
					null,
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		onUpdate(widgetObj);
	};

	const ref = useDetectClickOutside({ onTriggered: hideProductList });

	return (
		<>
			{choosenItem.name === "best-selling" ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<h1 className="title capitalize">Best Selling Products</h1>

					<TopPanel
						panel={panel}
						onChange={(name) => setPanel(name)}
					/>
					{/* <div className='checkbox'>
                    <div>Is Active: </div>
                    <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
                </div> */}

					{panel === "main" && (
						<div>
							<div>Featured Image: </div>
							{featuredImage.length > 0 ? (
								<ImageContainerEditButton
									index={0}
									imageUrl={
										process.env.REACT_APP_IMAGE_URL +
										featuredImage
									}
									handleImageSelector={handleImageSelector}
								/>
							) : (
								// <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + featuredImage} alt="" /> :
								<img
									className="rounded-md mt-2"
									src="/img/image-placeholder.jpg"
									alt=""
								/>
							)}
							<input
								value={title}
								onChange={handleTitle}
								type="text"
								className="my-2"
								placeholder="Title"
							/>
							<LinkForm
								currnetLinkData={link}
								onUpdate={handleLinkData}
							/>

							<div className="flex items-center gap-4 my-2">
								<div>Target: </div>
								<div className="flex items-center gap-4">
									<div
										onClick={() => {
											if (
												fetcturedImagelinkTarget ===
												"blank"
											) {
												setFetcturedImagelinkTarget("");
											} else {
												setFetcturedImagelinkTarget(
													"blank"
												);
											}
										}}
										className="flex items-center gap-2 cursor-pointer">
										<div
											className={`w-5 h-5 rounded-md ${
												fetcturedImagelinkTarget ===
												"blank"
													? "bg-green-500 shadow"
													: "bg-gray-300 shadow-inner"
											}`}></div>
										<div>Blank</div>
									</div>
								</div>
							</div>

							{/* <ImageSelector /> */}
							<ImageSelectorV2 onClick={handleImageSelector} />

							<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
								<CampaignSelector
									initialCampaign={
										choosenItem?.data?.selectedCampaign ||
										null
									}
									onUpdate={(data) => {
										setSelectedCampaign(data);
									}}
								/>
							</div>

							<div className="mt-4">
								<h1 className="title capitalize">Products</h1>
							</div>

							<div>
								<div>
									{
										<div className="w-full my-4">
											{choosenProducts.map(
												(
													item: Widget,
													index: number
												) => {
													return (
														<div
															key={index}
															className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
															<div className="capitalize text-xs font-light tracking-wide p-3">
																<div className="mb-2">
																	<span className="font-bold">
																		Product:{" "}
																	</span>
																	{item?.name}
																</div>
																<div>
																	<span className="font-bold">
																		Sku:{" "}
																	</span>
																	{
																		item?.monarchmart_sku
																	}
																</div>
															</div>
															<div
																onClick={() =>
																	handleRemoveProductId(
																		index
																	)
																}
																className="absolute -top-3 -right-3 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg">
																<CloseIcon />
															</div>
														</div>
													);
												}
											)}
										</div>
									}
								</div>
								<input
									value={text}
									onChange={handleChange}
									type="text"
									placeholder="search for products..."
								/>
								<div>
									{products.length > 0 && show && (
										<div
											className="bg-white px-3 shadow-lg divide-y max-h-96 min-h-fit overflow-y-scroll"
											ref={ref}>
											{products?.map((product) => {
												return (
													<div key={product.id}>
														<div
															className="flex flex-col items-center justify-between cursor-pointer py-2"
															onClick={() =>
																handleChoosenProduct(
																	product
																)
															}>
															<div className="text-xs font-light w-full">
																<span className="font-bold">
																	Product:
																</span>{" "}
																{product?.name}
															</div>
															<div className="text-xs font-light w-full">
																<span className="font-bold">
																	Sku:
																</span>{" "}
																{
																	product?.monarchmart_sku
																}
															</div>
															<div className="text-xs font-light flex items-center w-full justify-start">
																<span className="font-bold">
																	Price:
																</span>
																{product.discount_price ? (
																	<div>
																		<span className="text-gray-400 line-through mr-3">
																			&#2547;
																			{
																				product?.purchase_price
																			}
																		</span>
																		<span className="text-red-500">
																			&#2547;
																			{
																				product?.discount_price
																			}
																		</span>
																	</div>
																) : (
																	<div className="text-red-500">
																		{
																			product.purchase_price
																		}
																	</div>
																)}
															</div>
														</div>
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						</div>
					)}

					{panel === "settings" && (
						<ProductsDefaultSettings
							settings={settings}
							onChange={(key, value) => {
								setSettings({
									...settings,
									[key]: value,
								});
							}}
						/>
					)}

					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FormForBestSellingProdcuts;
