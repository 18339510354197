import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useParams } from "react-router-dom";

import "./pagedetails.css";

import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../../app/store";

import Banner from "../../components/Widgets/Banner";

import PlusIcon from "../../components/icons/PlusIcon";
import { Page } from "../../features/addPages/addPages";
import {
  getPageDetails,
  updateAllPages,
  updatePageData,
} from "../../modules/mediaManager";
import {
  PageCategory,
  Widget,
  mobileWidgets,
  webWidgets,
  widgetData,
  widgetNames,
  widgets,
} from "../../widgetList/widgetList";

import Cookies from "js-cookie";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { checkToken } from "../../App";
import { _axios } from "../../api";
import BrandsFilterSort from "../../components/BrandsFilterSort";
import { objectKeys } from "../../components/CWForm";
import ChangeLogPopup from "../../components/ChangeLogPopup";
import SettingFormItems, {
  SettingItem,
  SettingItems,
} from "../../components/SettingFormItems";
import Filter from "../../components/Widgets/Filter";
import Sorting from "../../components/Widgets/Sorting";
import WidgetFilter from "../../components/WidgetsFilter";
import CloseIcon from "../../components/icons/CloseIcon";
import { HistoryIcon } from "../../components/icons/icons";
import WidgetForm from "../../components/widgetFrom";
import { setRollbackPage } from "../../features/pageId/pageId";
import { setGlobalPopupElement, setLoading } from "../../features/popupSlice";
import { adminTokenCookieName } from "../../utils/constants";
import {
  getBrandList,
  getCategoryList,
  setPageActive,
} from "../../utils/requests";
import { ChokhIcon, confirmMessage } from "../HomePage";
import { showToastV2 } from "../WidgetBuilder";

import { FaClone } from "react-icons/fa";

const PageDetails: FC = () => {
  const widgetType = useSelector(
    (state: RootState) => state.widgetTypeFilter.type
  );
  const pageId = useSelector((state: RootState) => state.pageId.pageId);
  const permissions = useSelector(
    (state: RootState) => state.authPermission.permissions
  );

  const { pageTitle } = useParams();

  const [page, setPage] = useState<Page>({} as Page);
  const [pageStatus, setPageStatus] = useState("draft");

  const [pageSettings, setPageSettings] = useState<any>();

  //const page = getAllPages.find(page => page.name === pageTitle)

  const dispatch = useDispatch<AppDispatch>();

  const getData = async () => {
    dispatch(setLoading(true));
    await getPageDetails(pageTitle)
      .then((res) => {
        setPage(res.data);
        setPageStatus(res.data.status);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   dispatch(fetchAllPages())
  // }, [dispatch])

  useEffect(() => {
    setMainWidgets(page.widgets);
    setTopWidgets(page.topbar);
    setFilterWidgets(page.sidebar);
    setMainCategpry(page.categories);
    setMainBrand(page.brands);
    setMainCampaigns(page.campaigns);
    setPageSettings(page.settings);
  }, [page]);

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  //save reference for the dragItem and dropOverItem for top widgets
  const dragTopItem = React.useRef<any>(null);
  const dragOverTopItem = React.useRef<any>(null);

  // save reference for the dragItem and dragOverItem for sidebar widgets
  const draqSideItem = React.useRef<any>(null);
  const dragOverSideItem = React.useRef<any>(null);

  //item for push widget into the main widget list
  const itemForPush = React.useRef<any>(null);

  //level one

  const [highlightInputField, setHighlightInputField] = useState(false);
  const [highlightInputFieldForCategory, setHighlightInputFieldForCategory] =
    useState(false);
  const [highlightInputFieldForFilter, setHighlightInputFieldForFilter] =
    useState(false);
  const [mainWidgets, setMainWidgets] = useState<Widget[]>([]);
  const [highlightInputFieldForTop, setHighlightInputFieldForTop] =
    useState(false);
  const [topWidgets, setTopWidgets] = useState<Widget[]>([]);
  const [filterWidgets, setFilterWidgets] = useState<Widget[]>([]);
  const [type, setType] = useState<"widgets" | "topbar" | "sidebar">("widgets");
  const [dragItemIntoMainList, setDragItemIntoMainList] =
    useState<boolean>(false);

  //level two form
  const [choosenItem, setChoosenItem] = useState<Widget>({} as Widget);

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(true);
  //const[itemForPush, setItemForPush] = useState<Widget>({} as Widget)
  const [dragEnter, setDragEnter] = useState(false);

  //Page Category Search
  const [pageCategoryText, setPageCategoryText] = useState("");
  const [pageCategories, setPageCategories] = useState<any[]>([]);
  const [showPageCategory, setShowPageCategory] = useState(false);
  const [mainPageCategory, setMainCategpry] = useState<PageCategory[]>([]);

  //Page brand Search
  const [pageBrandText, setPageBrandText] = useState("");
  const [pageBrands, setPageBrands] = useState<any[]>([]);
  const [showPageBrand, setShowPageBrand] = useState(false);
  const [mainPageBrand, setMainBrand] = useState<PageCategory[]>([]);

  //Page Campaign Search
  const [pageCampaignText, setPageCampaignText] = useState("");
  const [pageCampaigns, setPageCampaigns] = useState<any[]>([]);
  const [showPageCampaigns, setShowPageCampaigns] = useState(false);
  const [mainPageCampaigns, setMainCampaigns] = useState<PageCategory[]>([]);

  const [dragStart, setDragStart] = useState(false);

  // page active
  const pageActive = page.active ?? !!page.is_published_one;

  const [activeSetLoading, setActiveSetLoading] = useState(false);

  const handlePageActive = () => {
    if (activeSetLoading) return;

    const handler = async (active: boolean) => {
      setActiveSetLoading(true);

      await setPageActive(page._id, active).then((page) => {
        setPage(page);
        console.log(page.status);
        setPageStatus(page.status);
      });

      setActiveSetLoading(false);
    };

    if (pageActive) {
      confirmMessage({
        title: "Are you sure?",
        message: "You are going to deactivate this page.",
        onConfirm: () => handler(false),
      });
    } else {
      confirmMessage({
        title: "Are you sure?",
        message: "You are going to activate this page.",
        onConfirm: () => handler(true),
      });
    }
  };

  //Functions for page category search
  const handlePageCategorySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageCategoryText(e.target.value);
    getPageCategory(e.target.value);
    if (pageCategories?.length > 0) {
      setShowPageCategory((prev) => (prev = !prev));
    }
  };
  const getPageCategory = async (text?: string) => {
    checkToken();
    await getCategoryList({
      search: text,
    }).then((res) => {
      setPageCategories(res?.data);
    });
  };
  const closePageCategoryResult = () => {
    setShowPageCategory(false);
  };
  const handleChoosePageCategory = (item: any) => {
    const _id = item.id;
    const _name = item.name;
    const _slug = item.slug;

    const newObj: PageCategory = {
      id: _id,
      name: _name,
      slug: _slug,
    };

    const _mainPageCategory = [...mainPageCategory];
    _mainPageCategory.push(newObj);
    setMainCategpry(_mainPageCategory);
  };

  const handleSavePageCategory = () => {
    setMainBrand([]);
    setMainCampaigns([]);
    patchWidgetsData(
      mainWidgets,
      "draft",
      "widgets",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      topWidgets,
      "draft",
      "topbar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      filterWidgets,
      "draft",
      "sidebar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
  };

  const handleDeletePageCategory = (index: number) => {
    const filteredPageCategory = mainPageCategory.filter((_, i) => i !== index);
    setMainCategpry(filteredPageCategory);
    patchWidgetsData(
      mainWidgets,
      "draft",
      "widgets",
      filteredPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      topWidgets,
      "draft",
      "topbar",
      filteredPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      filterWidgets,
      "draft",
      "sidebar",
      filteredPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
  };
  const refPageCategoryResult = useDetectClickOutside({
    onTriggered: closePageCategoryResult,
  });

  //Functions for page brand search
  const handlePageBrandSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageBrandText(e.target.value);
    getPageBrand(e.target.value);
    if (pageBrands?.length > 0) {
      setShowPageBrand((prev) => (prev = !prev));
    }
  };
  const getPageBrand = async (text: string) => {
    checkToken();
    await getBrandList({
      search: text,
    }).then((res) => {
      setPageBrands(res?.data);
    });
  };
  const closePageBrandResult = () => {
    setShowPageBrand(false);
  };
  const handleChoosePageBrand = (item: any) => {
    const _id = item.id;
    const _name = item.name;
    const _slug = item.slug;

    const newObj: PageCategory = {
      id: _id,
      name: _name,
      slug: _slug,
    };

    const _mainPageBrand = [...mainPageBrand];
    _mainPageBrand.push(newObj);
    setMainBrand(_mainPageBrand);
  };

  const handleSavePageBrand = () => {
    setMainCategpry([]);
    setMainCampaigns([]);
    patchWidgetsData(
      mainWidgets,
      "draft",
      "widgets",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      topWidgets,
      "draft",
      "topbar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      filterWidgets,
      "draft",
      "sidebar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
  };

  const handleDeletePageBrand = (index: number) => {
    const filteredPageBrand = mainPageBrand.filter((_, i) => i !== index);

    setMainBrand(filteredPageBrand);
    patchWidgetsData(
      mainWidgets,
      "draft",
      "widgets",
      mainPageCategory,
      filteredPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      topWidgets,
      "draft",
      "topbar",
      mainPageCategory,
      filteredPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      filterWidgets,
      "draft",
      "sidebar",
      mainPageCategory,
      filteredPageBrand,
      mainPageCampaigns
    );
  };

  //Function for page campaign search
  const handlePageCampaignsSearch = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageCampaignText(e.target.value);
    const text: string = e.target.value;
    if (text?.length > 0) {
      setShowPageCampaigns((prev) => (prev = !prev));
    }
    getPageCampaigns(text);
  };
  const getPageCampaigns = async (text: string) => {
    console.log("text =>", text);
    checkToken();
    await _axios
      .get(process.env.REACT_APP_CAMPAIGN_URL + "campaigns?s=" + text, {
        headers: {
          Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
        },
      })
      .then((res) => {
        console.log("res =>", res.data);
        setPageCampaigns(res.data.data);
      });
  };
  const closePageCampaignsResult = () => {
    setShowPageCampaigns(false);
  };
  const handleChoosePageCampaigns = (item: any) => {
    const _id = item.id;
    const _name = item.name;
    const _slug = item.slug;

    const newObj: PageCategory = {
      id: _id,
      name: _name,
      slug: _slug,
    };

    const _mainPageCampaigns = [...mainPageCampaigns];
    if (mainPageCampaigns?.length < 1) {
      _mainPageCampaigns.push(newObj);
    }
    setMainCampaigns(_mainPageCampaigns);
    setPageCampaignText("");
  };

  const handleSavePageCampaigns = () => {
    setMainCategpry([]);
    setMainBrand([]);
    patchWidgetsData(
      mainWidgets,
      "draft",
      "widgets",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      topWidgets,
      "draft",
      "topbar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    patchWidgetsData(
      filterWidgets,
      "draft",
      "sidebar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    showToast("Added and saved as draft");
  };

  const handleDeletePageCampaigns = (index: number) => {
    const filteredPageCampaigns = mainPageCampaigns.filter(
      (_, i) => i !== index
    );
    setMainCampaigns(filteredPageCampaigns);
    patchWidgetsData(
      mainWidgets,
      "draft",
      "widgets",
      mainPageCategory,
      mainPageBrand,
      filteredPageCampaigns
    );
    patchWidgetsData(
      topWidgets,
      "draft",
      "topbar",
      mainPageCategory,
      mainPageBrand,
      filteredPageCampaigns
    );
    patchWidgetsData(
      filterWidgets,
      "draft",
      "sidebar",
      mainPageCategory,
      mainPageBrand,
      filteredPageCampaigns
    );
  };

  const refPageBrandResult = useDetectClickOutside({
    onTriggered: closePageBrandResult,
  });
  const refPageCampaignsResult = useDetectClickOutside({
    onTriggered: closePageCampaignsResult,
  });

  const showToast = (message: string) => {
    checkToken();
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 2500,
    });
    Toast.fire({
      icon: "success",
      title: message,
    });
  };

  // useEffect(()=>{
  //   patchPageData("draft")
  // },[mainWidgets])

  const onFormUpdate = (w: Widget) => {
    patchPageData("draft", w, mainPageCategory);
    // dispatch(clearSetting())
  };

  const patchPageData = (
    status: "draft" | "published",
    cW: Widget | null,
    categories: PageCategory[]
  ) => {
    setPageStatus(status);

    let wData: Widget[] = [];

    let changeLogs: string[] = [];

    const updateChangeLogs = (prev: Widget, current: Widget) => {
      console.log("prev =>", prev);
      console.log("current =>", current);
      if (JSON.stringify(prev.title) !== JSON.stringify(current.title)) {
        changeLogs.push(`${prev.name}: Title setting updated`);
      } else if (JSON.stringify(prev.link) !== JSON.stringify(current.link)) {
        changeLogs.push(`${prev.name}: Link setting updated`);
      } else if (
        JSON.stringify(prev.active) !== JSON.stringify(current.active)
      ) {
        changeLogs.push(`${prev.name}: Active setting updated`);
      } else if (JSON.stringify(prev.data) !== JSON.stringify(current.data)) {
        const pData = prev.data,
          cData = current.data;

        if (pData && cData) {
          const keys = objectKeys(pData);
          keys.forEach((key) => {
            if (
              !!pData[key] &&
              !!cData[key] &&
              typeof pData[key] === "object" &&
              !Array.isArray(pData[key])
            ) {
              const subKeys = objectKeys(pData[key]);
              console.log("typpeof", typeof pData[key], pData[key], cData[key]);

              subKeys.forEach((subKey) => {
                console.log(
                  "subKey",
                  subKey,
                  pData[key]?.[subKey],
                  cData[key]?.[subKey]
                );
                if (
                  JSON.stringify(pData[key]?.[subKey]) !==
                  JSON.stringify(cData[key]?.[subKey])
                ) {
                  const name = (subKey as string)?.replace(/([A-Z])/g, " $1");
                  const upperName =
                    name?.charAt(0).toUpperCase() + name?.slice(1);
                  console.log("upperName", upperName);
                  changeLogs.push(`${prev.name}: ${upperName} setting updated`);
                }
              });
            } else if (
              JSON.stringify(pData[key]) !== JSON.stringify(cData[key])
            ) {
              const name = key?.replace(/([A-Z])/g, " $1");
              const upperName = name?.charAt(0).toUpperCase() + name?.slice(1);
              changeLogs.push(`${prev.name}: ${upperName} setting updated`);
            }
          });
        }

        // if (!pData || !cData) {
        //   changeLogs.push(`${prev.name}: Data setting updated`)
        // }
        // else if (JSON.stringify(pData.images) !== JSON.stringify(cData.images)) {
        //   changeLogs.push(`${prev.name}: Images setting updated`)
        // } else if (JSON.stringify(pData.selectedCampaign) !== JSON.stringify(cData.selectedCampaign)) {
        //   changeLogs.push(`${prev.name}: Selected Campaign setting updated`)
        // } else if (JSON.stringify(pData.showTimer) !== JSON.stringify(cData.showTimer)) {
        //   changeLogs.push(`${prev.name}: Show Timer setting updated`)
        // } else if (JSON.stringify(pData.setting) !== JSON.stringify(cData.setting)) {
        //   changeLogs.push(`${prev.name}: Setting updated`)
        // } else if (JSON.stringify(pData.products) !== JSON.stringify(cData.products)) {
        //   changeLogs.push(`${prev.name}: Products setting updated`)
        // } else if (JSON.stringify(pData.featuredProduct) !== JSON.stringify(cData.featuredProduct)) {
        //   changeLogs.push(`${prev.name}: Featured Product setting updated`)
        // } else if (JSON.stringify(pData.shops) !== JSON.stringify(cData.shops)) {
        //   changeLogs.push(`${prev.name}: Shops setting updated`)
        // } else if (JSON.stringify(pData.bestSellingProducts) !== JSON.stringify(cData.bestSellingProducts)) {
        //   changeLogs.push(`${prev.name}: Best Selling Products setting updated`)
        // } else if (JSON.stringify(pData.singleProductSlider) !== JSON.stringify(cData.singleProductSlider)) {
        //   changeLogs.push(`${prev.name}: Single Product Slider setting updated`)
        // } else if (JSON.stringify(pData.electronicsApplience) !== JSON.stringify(cData.electronicsApplience)) {
        //   changeLogs.push(`${prev.name}: Electronics Applience setting updated`)
        // } else if (JSON.stringify(pData.sellerProfile) !== JSON.stringify(cData.sellerProfile)) {
        //   changeLogs.push(`${prev.name}: Seller Profile setting updated`)
        // } else if (JSON.stringify(pData.content) !== JSON.stringify(cData.content)) {
        //   changeLogs.push(`${prev.name}: Content setting updated`)
        // } else if (JSON.stringify(pData.rowLimit) !== JSON.stringify(cData.rowLimit)) {
        //   changeLogs.push(`${prev.name}: Row Limit setting updated`)
        // } else if (JSON.stringify(pData.isSlider) !== JSON.stringify(cData.isSlider)) {
        //   changeLogs.push(`${prev.name}: Is Slider setting updated`)
        // } else if (JSON.stringify(pData.backgroundColor) !== JSON.stringify(cData.backgroundColor)) {
        //   changeLogs.push(`${prev.name}: Background Color setting updated`)
        // } else if (JSON.stringify(pData.inDemandObj) !== JSON.stringify(cData.inDemandObj)) {
        //   changeLogs.push(`${prev.name}: In Demand Obj setting updated`)
        // } else if (JSON.stringify(pData.rightImages) !== JSON.stringify(cData.rightImages)) {
        //   changeLogs.push(`${prev.name}: Right Images setting updated`)
        // } else {
        //   changeLogs.push(`${prev.name}: Data setting updated`)
        // }
      } else if (
        JSON.stringify(prev.category) !== JSON.stringify(current.category)
      ) {
        changeLogs.push(`${prev.name}: Category setting updated`);
      } else if (
        JSON.stringify(prev.imageLimit) !== JSON.stringify(current.imageLimit)
      ) {
        changeLogs.push(`${prev.name}: Image Limit setting updated`);
      } else if (
        JSON.stringify(prev.featuredImage) !==
        JSON.stringify(current.featuredImage)
      ) {
        changeLogs.push(`${prev.name}: Featured Image setting updated`);
      } else if (
        JSON.stringify(prev.fetchuredImageUrl) !==
        JSON.stringify(current.fetchuredImageUrl)
      ) {
        changeLogs.push(`${prev.name}: Featured Image Url setting updated`);
      } else if (
        JSON.stringify(prev.fetcturedImagePosition) !==
        JSON.stringify(current.fetcturedImagePosition)
      ) {
        changeLogs.push(
          `${prev.name}: Featured Image Position setting updated`
        );
      } else if (
        JSON.stringify(prev.productLimit) !==
        JSON.stringify(current.productLimit)
      ) {
        changeLogs.push(`${prev.name}: Product Limit setting updated`);
      } else if (
        JSON.stringify(prev.brandList) !== JSON.stringify(current.brandList)
      ) {
        changeLogs.push(`${prev.name}: Brand List setting updated`);
      } else if (
        JSON.stringify(prev.rightImages) !== JSON.stringify(current.rightImages)
      ) {
        changeLogs.push(`${prev.name}: Right Images setting updated`);
      } else if (
        JSON.stringify(prev.rightImagesPosition) !==
        JSON.stringify(current.rightImagesPosition)
      ) {
        changeLogs.push(`${prev.name}: Right Images Position setting updated`);
      } else if (JSON.stringify(prev.wType) !== JSON.stringify(current.wType)) {
        changeLogs.push(`${prev.name}: Widget Type setting updated`);
      } else if (
        JSON.stringify(prev.fetcturedImagelinkTarget) !==
        JSON.stringify(current.fetcturedImagelinkTarget)
      ) {
        changeLogs.push(
          `${prev.name}: Featured Image Link Target setting updated`
        );
      } else {
        changeLogs.push(`${prev.name}: Widget updated`);
      }
    };

    if (cW) {
      if (cW?.wType === "widgets") {
        const exist = mainWidgets?.find((item) => item.id === cW.id);
        if (exist) {
          wData = mainWidgets?.map((i) => {
            const item = { ...i };
            if (
              item.name === cW.name &&
              cW.id &&
              item.id &&
              item.id === cW.id
            ) {
              // && cW.id && item.id && item.id === cW.id
              updateChangeLogs(item, cW);

              item.title = cW.title;
              item.link = cW.link;
              item.active = cW.active;
              item.data = cW.data;
              item.category = cW.category;
              item.imageLimit = cW.imageLimit;
              item.featuredImage = cW.featuredImage;
              item.fetchuredImageUrl = cW.fetchuredImageUrl;
              item.fetcturedImagePosition = cW.fetcturedImagePosition;
              item.productLimit = cW.productLimit;
              item.brandList = cW.brandList;
              item.rightImages = cW.rightImages;
              item.rightImagesPosition = cW.rightImagesPosition;
              item.wType = cW.wType;
              item.fetcturedImagelinkTarget = cW.fetcturedImagelinkTarget;
            }
            return item;
          });
        } else {
          wData = mainWidgets.concat(cW);
          changeLogs.push(`${cW.name}: Widget added in main widgets`);
        }
      } else if (cW?.wType === "topbar") {
        const exist = topWidgets?.find((item) => item.name === cW.name);
        if (exist) {
          wData = topWidgets?.map((i) => {
            const item = { ...i };
            if (
              item.name === cW.name &&
              cW.id &&
              item.id &&
              item.id === cW.id
            ) {
              // && cW.id && item.id && item.id === cW.id
              updateChangeLogs(item, cW);

              item.title = cW.title;
              item.link = cW.link;
              item.active = cW.active;
              item.data = cW.data;
              item.category = cW.category;
              item.imageLimit = cW.imageLimit;
              item.featuredImage = cW.featuredImage;
              item.fetchuredImageUrl = cW.fetchuredImageUrl;
              item.fetcturedImagePosition = cW.fetcturedImagePosition;
              item.productLimit = cW.productLimit;
              item.brandList = cW.brandList;
              item.rightImages = cW.rightImages;
              item.rightImagesPosition = cW.rightImagesPosition;
              item.wType = cW.wType;
              item.fetcturedImagelinkTarget = cW.fetcturedImagelinkTarget;
            }
            return item;
          });
        } else {
          wData = mainWidgets.concat(cW);
          changeLogs.push(`${cW.name}: Widget added in topbar`);
        }
      } else if (cW?.wType === "sidebar") {
        const exist = filterWidgets?.find((item) => item.name === cW.name);
        if (exist) {
          wData = filterWidgets?.map((i) => {
            const item = { ...i };
            if (
              item.name === cW.name &&
              cW.id &&
              item.id &&
              item.id === cW.id
            ) {
              // && cW.id && item.id && item.id === cW.id
              updateChangeLogs(item, cW);

              item.title = cW.title;
              item.link = cW.link;
              item.active = cW.active;
              item.data = cW.data;
              item.category = cW.category;
              item.imageLimit = cW.imageLimit;
              item.featuredImage = cW.featuredImage;
              item.fetchuredImageUrl = cW.fetchuredImageUrl;
              item.fetcturedImagePosition = cW.fetcturedImagePosition;
              item.productLimit = cW.productLimit;
              item.brandList = cW.brandList;
              item.rightImages = cW.rightImages;
              item.rightImagesPosition = cW.rightImagesPosition;
              item.wType = cW.wType;
              item.fetcturedImagelinkTarget = cW.fetcturedImagelinkTarget;
            }
            return item;
          });
        } else {
          wData = mainWidgets.concat(cW);
          changeLogs.push(`${cW.name}: Widget added in sidebar`);
        }
      } else {
        return null;
      }
    } else {
      wData = mainWidgets?.map((i) => {
        const item = { ...i };
        if (item.name === name) {
          item.title = title;
          item.active = active;
          //item.data = { ...item.data, image: choosenAllImages}
        }
        return item;
      });
    }

    setMainWidgets(wData);

    console.log("CLick Form Update!", changeLogs);

    const newData = {
      ...page,
      status: status,
      change_log: changeLogs.join(", "),
      settings: pageSettings,
    };

    updateAllPages(
      newData,
      wData,
      cW?.wType,
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    )
      .then((res) => {
        // dispatch(fetchAllPages())
        setPage(res.data);
        if (status === "published") {
          swal("Good job!", "Page published", "success");
        } else {
          showToast("Added and saved as draft");
        }
      })
      .catch(() => {
        swal("Something went wrong!", "", "error");
      });
  };

  // rollback functionality
  const rollbackPage = useSelector(
    (state: RootState) => state.pageId.rollbackPage
  );

  useEffect(() => {
    if (rollbackPage !== null) {
      updatePageData({
        ...rollbackPage,
        change_log: "Rollback to previous version",
      })
        .then((data) => {
          setPage(data);
          dispatch(setRollbackPage(null));
          dispatch(setGlobalPopupElement(null));
          showToast("Rollback successful");

          // web page reload
          // window.location.reload();
          setChoosenItem({} as Widget);
        })
        .catch(() => {
          swal("Something went wrong!", "", "error");
        });
    }
  }, [rollbackPage, dispatch]);

  const patchWidgetsData = (
    widgets: Widget[],
    status: "draft" | "publish",
    type: "widgets" | "sidebar" | "topbar",
    categories: PageCategory[],
    brands: PageCategory[],
    campaigns: PageCategory[]
  ) => {
    console.log("test");
    console.log("test two");
    const newData = {
      ...page,
      status: status,
      categories: categories,
      brands: brands,
      campaigns: campaigns,
    };
    updateAllPages(newData, widgets, type, categories, brands, campaigns);
  };

  //level one functions
  const handleToggle = () => {
    if (pageStatus === "draft") {
      setPageStatus("published");
      patchPageData("published", null, mainPageCategory);
    } else {
      setPageStatus("draft");
    }
  };

  const handleOnDrang = (
    e: React.DragEvent<HTMLDivElement>,
    item: Widget,
    index: number
  ) => {
    e.preventDefault();
    setDragStart(false);

    if (
      !(
        item.name === widgetNames.infiniteProductRow &&
        !!mainWidgets.find((w) => w.name === widgetNames.infiniteProductRow)
      )
    ) {
      const _mainWidgets = [...mainWidgets];
      const _id: string = mainWidgets?.length + 1 + `-` + new Date().getTime();
      _mainWidgets.push({ ...item, id: _id });

      if (highlightInputField) {
        setMainWidgets(_mainWidgets);
        patchWidgetsData(
          _mainWidgets,
          "draft",
          "widgets",
          mainPageCategory,
          mainPageBrand,
          mainPageCampaigns
        );
      }

      if (highlightInputFieldForTop) {
        const _topWidgets = [...topWidgets];
        const _id: string = topWidgets?.length + 1 + `-` + new Date().getTime();
        _topWidgets.push({ ...item, id: _id });
        setTopWidgets(_topWidgets);
        patchWidgetsData(
          _topWidgets,
          "draft",
          "topbar",
          mainPageCategory,
          mainPageBrand,
          mainPageCampaigns
        );
      }

      if (highlightInputFieldForFilter) {
        const _filterWidgets = [...filterWidgets];
        const _id: string =
          filterWidgets?.length + 1 + `-` + new Date().getTime();
        _filterWidgets.push({ ...item, id: _id });
        setFilterWidgets(_filterWidgets);
        patchWidgetsData(
          _filterWidgets,
          "draft",
          "sidebar",
          mainPageCategory,
          mainPageBrand,
          mainPageCampaigns
        );
      }
    } else {
      showToastV2("You can only add one Infinite Product Row widget", "error");
    }

    setHighlightInputField(false);
    setHighlightInputFieldForCategory(false);
    setHighlightInputFieldForFilter(false);
    setHighlightInputFieldForTop(false);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHighlightInputField(true);
  };

  const handleOnDropForTop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHighlightInputFieldForTop(true);
  };

  const handleOnDropForFilter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHighlightInputFieldForFilter(true);
  };

  const handleOnClick = (item: Widget, index: number) => {
    setChoosenItem(item);
    // setName(item.name)
    // //setTitle(item.title)
    // setActive(item.active)
  };

  //level two from functions
  const handleDragEnter = (item: Widget, index: number) => {
    dragOverItem.current = index;
  };
  const handleDragEnterForTopWidget = (item: Widget, index: number) => {
    dragOverTopItem.current = index;
  };
  const handleDragEnterForSideWidget = (item: Widget, index: number) => {
    dragOverSideItem.current = index;
  };

  // const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) =>{
  //   setTitle(e.target.value)
  // }
  // const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) =>{
  //   setActive(e.target.checked)
  // }
  // const handleOnSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
  //   e.preventDefault()

  //   patchPageData("draft")
  // }

  const handleSortForSideWidget = (e: React.DragEvent<HTMLDivElement>) => {
    if (
      !!filterWidgets.find(
        (item) => item.name === widgetNames.infiniteProductRow
      )
    ) {
      showToastV2("You can not move this widget", "error");
      return;
    }

    let _filterWidgets = [...filterWidgets];

    const draggedItemContent = _filterWidgets.splice(
      draqSideItem.current,
      1
    )[0];
    _filterWidgets.splice(dragOverSideItem.current, 0, draggedItemContent);

    const filteredW = _filterWidgets.filter(
      (item) => item.name !== widgetNames.infiniteProductRow
    );
    const filteredW2 = _filterWidgets.filter(
      (item) => item.name === widgetNames.infiniteProductRow
    );
    _filterWidgets = [...filteredW, ...filteredW2];

    draqSideItem.current = null;
    dragOverSideItem.current = null;

    setFilterWidgets(_filterWidgets);

    const taget = e.target as HTMLImageElement;
    taget.style.opacity = "1";
    patchWidgetsData(
      _filterWidgets,
      "draft",
      "sidebar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    setDragStart(true);
  };

  const handleSortForTopWidget = (e: React.DragEvent<HTMLDivElement>) => {
    if (
      !!topWidgets.find((item) => item.name === widgetNames.infiniteProductRow)
    ) {
      showToastV2("You can not move this widget", "error");
      return;
    }

    let _topWidgets = [...topWidgets];

    const draggedItemContent = _topWidgets.splice(dragTopItem.current, 1)[0];
    _topWidgets.splice(dragOverTopItem.current, 0, draggedItemContent);

    const filteredW = _topWidgets.filter(
      (item) => item.name !== widgetNames.infiniteProductRow
    );
    const filteredW2 = _topWidgets.filter(
      (item) => item.name === widgetNames.infiniteProductRow
    );
    _topWidgets = [...filteredW, ...filteredW2];

    dragTopItem.current = null;
    dragOverTopItem.current = null;

    setTopWidgets(_topWidgets);

    const taget = e.target as HTMLImageElement;
    taget.style.opacity = "1";
    patchWidgetsData(
      _topWidgets,
      "draft",
      "topbar",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
    setDragStart(true);
  };

  const handleSort = (e: React.DragEvent<HTMLDivElement>) => {
    setDragStart(false);

    if (
      !!mainWidgets.find((item) => item.name === widgetNames.infiniteProductRow)
    ) {
      showToastV2("You can not move this widget", "error");
      return;
    }
    let _mainWidgets = [...mainWidgets];

    console.log("test for short =>", _mainWidgets);

    const draggedItemContent = _mainWidgets.splice(dragItem.current, 1)[0];
    _mainWidgets.splice(dragOverItem.current, 0, draggedItemContent);

    const filteredW = _mainWidgets.filter(
      (item) => item.name !== widgetNames.infiniteProductRow
    );
    const filteredW2 = _mainWidgets.filter(
      (item) => item.name === widgetNames.infiniteProductRow
    );
    _mainWidgets = [...filteredW, ...filteredW2];

    dragItem.current = null;
    dragOverItem.current = null;
    itemForPush.current = null;

    setMainWidgets(_mainWidgets);

    const taget = e.target as HTMLImageElement;
    taget.style.opacity = "1";

    patchWidgetsData(
      _mainWidgets,
      "draft",
      "widgets",
      mainPageCategory,
      mainPageBrand,
      mainPageCampaigns
    );
  };

  const handleOnDropForPushItem = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    setDragStart(false);

    if (dragItemIntoMainList) {
      if (
        itemForPush.current &&
        itemForPush.current.name === widgetNames.infiniteProductRow &&
        !!mainWidgets.find(
          (item) => item.name === widgetNames.infiniteProductRow
        )
      ) {
        return;
      }

      let _mainWidgets = [...mainWidgets];
      const _id: string = mainWidgets?.length + 1 + `-` + new Date().getTime();
      _mainWidgets.splice(index, 0, { ...itemForPush.current, id: _id });

      const filteredW = _mainWidgets.filter(
        (item) => item.name !== widgetNames.infiniteProductRow
      );
      const filteredW2 = _mainWidgets.filter(
        (item) => item.name === widgetNames.infiniteProductRow
      );
      _mainWidgets = [...filteredW, ...filteredW2];

      setMainWidgets(_mainWidgets);
      patchWidgetsData(
        _mainWidgets,
        "draft",
        "widgets",
        mainPageCategory,
        mainPageBrand,
        mainPageCampaigns
      );
    }
  };

  const handleRemoveTopWidget = (item: Widget) => {
    checkToken();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this widget setting!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Your page file has been deleted!", {
          icon: "success",
        });
        const newWidgetsList = topWidgets.filter(
          (widget) => widget.name !== item.name
        );
        let res;
        try {
          res = await _axios.patch(
            process.env.REACT_APP_BUILDER_URL + "page/" + page?._id,
            {
              topbar: newWidgetsList,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
              },
            }
          );
          getData();
        } catch (error) {
          throw error;
        }

        return res;
      } else {
        swal("Your page file is safe!");
      }
    });
  };
  const handleRemoveFilterWidget = (item: Widget) => {
    checkToken();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this widget setting!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Your page file has been deleted!", {
          icon: "success",
        });
        const newWidgetsList = filterWidgets.filter(
          (widget) => widget.id !== item.id
        );
        let res;
        try {
          res = await _axios.patch(
            process.env.REACT_APP_BUILDER_URL + "page/" + page?._id,
            {
              sidebar: newWidgetsList,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
              },
            }
          );
          getData();
        } catch (error) {
          throw error;
        }

        return res;
      } else {
        swal("Your page file is safe!");
      }
    });
  };

  const handleRemoveWidget = async (item: Widget) => {
    checkToken();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this widget setting!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Your page file has been deleted!", {
          icon: "success",
        });
        const newWidgetsList = mainWidgets?.filter(
          (widget) => widget.id !== item.id
        );
        let res;
        try {
          res = await _axios.patch(
            process.env.REACT_APP_BUILDER_URL + "page/" + page?._id,
            {
              widgets: newWidgetsList,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
              },
            }
          );
          getData();
        } catch (error) {
          throw error;
        }

        if (item.id === choosenItem.id) {
          setChoosenItem({} as Widget);
        }

        return res;
      } else {
        swal("Your page file is safe!");
      }
    });
  };

  const handleCloneWidget = (item: Widget, index?: number) => (e: any) => {
    e.preventDefault();

    if (index === undefined) {
      const _id: string = mainWidgets?.length + 1 + `-` + new Date().getTime();
      const _mainWidgets = [...mainWidgets, { ...item, id: _id }];
      setMainWidgets(_mainWidgets);
      patchWidgetsData(
        _mainWidgets,
        "draft",
        "widgets",
        mainPageCategory,
        mainPageBrand,
        mainPageCampaigns
      );
    } else {
      const _id: string = mainWidgets?.length + 1 + `-` + new Date().getTime();
      const _mainWidgets = [...mainWidgets];
      _mainWidgets.splice(index, 0, { ...item, id: _id });
      setMainWidgets(_mainWidgets);
      patchWidgetsData(
        _mainWidgets,
        "draft",
        "widgets",
        mainPageCategory,
        mainPageBrand,
        mainPageCampaigns
      );
    }
    setPageStatus("draft");
  };

  const changeLogPopupOpen = () => {
    dispatch(setGlobalPopupElement(<ChangeLogPopup pageId={page._id} />));
  };

  const activeWidgetClass: string = "border border-blue-500 rounded-md";

  return (
    <div className="pagedetails__container">
      <div className="pagedetails__row--one">
        <div className="tracking-wide flex gap-2 items-center">
          <div
            className={
              "w-2 h-2 rounded-full " +
              (pageActive ? "bg-green-500" : "bg-gray-500")
            }
          ></div>

          {page?.name}

          <a
            href={process.env.REACT_APP_FRONTEND_PREVIEW_URL + page?._id + ""}
            target="_blank"
            rel="noreferrer"
            title="Preview"
          >
            <div className="p-1">
              <ChokhIcon className="stroke-black chock-icon w-4 h-4 scale-x-110" />
            </div>
          </a>
        </div>
        {/* <a
          href={process.env.REACT_APP_FRONTEND_PREVIEW_URL + page?._id + ''}
          target="_blank"
          rel="noreferrer"
          className='text-[1rem] text-gray-800 px-2 border border-gray-500 rounded-md'
        >PREVIEW</a> */}
        {permissions.includes("publish_page_layout") && (
          <div className="flex items-center gap-2">
            {/* last updated data and time */}
            {/* <div className='text-xs font-light tracking-wide'>Last Updated :</div> */}
            <div className="text-xs font-light tracking-wide">
              {!!page?.updatedAt && new Date(page?.updatedAt).toLocaleString()}
            </div>
            {/* <div className='text-sm font-light tracking-wide'>Publish :</div> */}
            <div>
              {/* page publish toggle */}
              {pageActive && (
                <div
                  className={`w-12 h-fit rounded-full flex items-center p-1 shadow-inner ${
                    pageStatus === "draft"
                      ? "justify-start bg-gray-400"
                      : "justify-end bg-green-500"
                  }`}
                >
                  <div
                    onClick={handleToggle}
                    className="w-4 h-4 rounded-full bg-white drop-shadow-lg cursor-pointer"
                  ></div>
                </div>
              )}
            </div>
            {/* page active button */}
            <button
              disabled={activeSetLoading}
              onClick={handlePageActive}
              className={`px-1.5 text-sm rounded-lg border ${
                pageActive
                  ? "bg-green-500 border-green-500 text-white"
                  : "text-orange-500 border-orange-500"
              }`}
            >
              {activeSetLoading
                ? "Loading..."
                : pageActive
                ? "Active"
                : "Inactive"}
            </button>

            <button onClick={changeLogPopupOpen}>
              <HistoryIcon className="w-5 h-5" />
            </button>
            {/* {Cookies.get(cookieSecret.key) === cookieSecret.value && (
            )} */}
          </div>
        )}
      </div>

      <div className="pagedetails__row--two">
        <div>
          <div>
            <WidgetFilter />
          </div>
          <div className="pagedetails__col--one">
            <div className="pagedetails__col--one-wrapper">
              {widgetType === "all" &&
                widgets?.map((item, index) => {
                  const Comp =
                    item.Component ||
                    widgetData[item.name]?.Component ||
                    Banner;

                  return (
                    <div key={index} className="mb-3 last:mb-0">
                      <div
                        className="border-b pb-3"
                        onDragStart={() => {
                          itemForPush.current = item;
                          setDragItemIntoMainList(true);
                          setDragStart(true);
                        }}
                        onDragEnd={(e) => {
                          handleOnDrang(e, item, index);

                          setDragStart(false);
                        }}
                        draggable="true"
                      >
                        <p className="capitalize text-center mb-2 text-xs tracking-wider">
                          {item.name}
                        </p>
                        <Comp />
                      </div>
                    </div>
                  );
                })}
              {widgetType === "web" &&
                webWidgets?.map((item, index) => {
                  const Comp =
                    item.Component ||
                    widgetData[item.name]?.Component ||
                    Banner;

                  return (
                    <div key={index} className="mb-3 last:mb-0">
                      <div
                        className="border-b pb-3"
                        onDragStart={() => {
                          itemForPush.current = item;
                          setDragItemIntoMainList(true);

                          setDragStart(true);
                        }}
                        onDragEnd={(e) => handleOnDrang(e, item, index)}
                        draggable="true"
                      >
                        <p className="capitalize text-center mb-2 text-xs tracking-wider">
                          {item.name}
                        </p>
                        <Comp />
                      </div>
                    </div>
                  );
                })}
              {widgetType === "mobile" &&
                mobileWidgets?.map((item, index) => {
                  const Comp =
                    item.Component ||
                    widgetData[item.name]?.Component ||
                    Banner;

                  return (
                    <div key={index} className="mb-3 last:mb-0">
                      <div
                        className="border-b pb-3"
                        onDragStart={() => {
                          itemForPush.current = item;
                          setDragItemIntoMainList(true);

                          setDragStart(true);
                        }}
                        onDragEnd={(e) => handleOnDrang(e, item, index)}
                        draggable="true"
                      >
                        <p className="capitalize text-center mb-2 text-xs tracking-wider">
                          {item.name}
                        </p>
                        <Comp />
                      </div>
                    </div>
                  );
                })}
              {(widgetType === "page" || widgetType === "top-bar") &&
                webWidgets?.map((item, index) => {
                  const Comp =
                    item.Component ||
                    widgetData[item.name]?.Component ||
                    Banner;

                  return (
                    <div key={index} className="mb-3 last:mb-0">
                      <div
                        className="border-b pb-3"
                        onDragStart={() => {
                          itemForPush.current = item;
                          setDragItemIntoMainList(true);

                          setDragStart(true);
                        }}
                        onDragEnd={(e) => handleOnDrang(e, item, index)}
                        draggable="true"
                      >
                        <p className="capitalize text-center mb-2 text-xs tracking-wider">
                          {item.name}
                        </p>
                        <Comp />
                      </div>
                    </div>
                  );
                })}
              {(widgetType === "product-section" ||
                widgetType === "side-bar") &&
                webWidgets?.map((item, index) => {
                  const Comp =
                    item.Component ||
                    widgetData[item.name]?.Component ||
                    Banner;

                  return (
                    <div key={index} className="mb-3 last:mb-0">
                      <div
                        className="border-b pb-3"
                        onDragStart={() => {
                          itemForPush.current = item;
                          setDragItemIntoMainList(true);

                          setDragStart(true);
                        }}
                        onDragEnd={(e) => handleOnDrang(e, item, index)}
                        draggable="true"
                      >
                        <p className="capitalize text-center mb-2 text-xs tracking-wider">
                          {item.name}
                        </p>
                        <Comp />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="pagedetails__col--two">
          {(page?.type === "category" || page?.type === "brand") && (
            <div>
              <div className="mb-4 bg-white drop-shadow-lg rounded-md p-4">
                <div
                  className={`input__field ${
                    highlightInputFieldForCategory ? "bg-gray-200" : "bg-white"
                  }`}
                  onDragOver={(e) => {
                    handleOnDropForTop(e);
                  }}
                >
                  <PlusIcon />
                </div>
                <div
                  className={`${
                    topWidgets?.length > 0 && "pt-3 mt-3 border-t"
                  }`}
                >
                  {topWidgets?.map((item, index) => {
                    const active = item.id === choosenItem?.id;
                    const Comp =
                      item.Component ||
                      widgetData[item.name]?.Component ||
                      Banner;

                    return (
                      <div
                        key={index}
                        className={
                          "relative border-b pb-3 last:pb-0 mb-3 last:mb-0 " +
                          (active ? activeWidgetClass : "last:border-none")
                        }
                      >
                        <div
                          onClick={() => {
                            handleOnClick(
                              {
                                ...item,
                                wType: "topbar",
                              },
                              index
                            );
                          }}
                          onDragStart={(e) => {
                            dragItem.current = index;
                            setDragItemIntoMainList(false);
                            setDragStart(true);
                          }}
                          onDragEnter={() => {
                            handleDragEnterForTopWidget(item, index);
                            setDragEnter(true);
                          }}
                          onDragEnd={handleSortForTopWidget}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            const target = e.target as HTMLImageElement;
                            target.style.opacity = "1";
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            const target = e.target as HTMLImageElement;
                            target.style.opacity = "0.2";
                          }}
                          onDrop={(e) => handleOnDropForPushItem(e, index)}
                          draggable="true"
                        >
                          <Comp />
                          {/* {item.name === 'title' ? <Title /> : item.name === 'slider' ? <MainSlider /> : item.name === 'banner' ? <Banner /> : item.name === '3-collum-images' ? <ThreeCollumsImages /> : item.name === 'populer-product' ? <PopulerProduct /> : item.name === 'categories' ? <Category /> : item.name === 'product-row' ? <ProductRow /> : item.name === 'one-collum-add' ? <OneCollumAdd /> : item.name === 'two-collum-add' ? <TwoCollumAdd /> : item.name === 'brand-list' ? <Brands /> : item.name === 'seller-list' ? <Sellers /> : item.name === 'new-arrival' ? <NewArrival /> : item.name === 'product-slider' ? <ProductSlider /> : item.name === 'todays-deal' ? <TodaysDeal /> : item.name === 'highlighted-shop' ? <HighlightedShop /> : item.name === 'best-selling' ? <BestSellingProducts /> : item.name === 'single-product' ? <SingleProduct /> : item.name === 'electronics-applience' ? <ElectronicsApplience /> : item.name === 'seller-profile' ? <SellerProfile /> : item.name === 'content-editor' ? <ContentEditor /> : item.name === 'product-row-limit' ? <ProductsRowLimit /> : item.name === 'in-demand' ? <InDemand /> : item.name === widgetNames.infiniteProductRow ? <ProductsRowLimit /> : item.name === widgetNames.smallWideBanner ? <SmallWideBanner /> : item.name === widgetNames.banners ? <ThreeCollumsImages /> : item.name === widgetNames.trendingProducts ? <TrendingProducts /> : item.name === widgetNames.threeBannerProducts ? <ThreeBannersProducts /> : item.name === widgetNames.filterProducts ? <FilterProducts /> : item.name === widgetNames.randomSizeBanners ? <RandomBanners /> : null} */}
                        </div>
                        <div
                          onClick={() => handleRemoveTopWidget(item)}
                          className="absolute z-10 -top-3 -right-3 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
                        >
                          <CloseIcon />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mb-4">
                <Sorting />
              </div>
            </div>
          )}
          <div className="flex items-start gap-4 w-full">
            {(page?.type === "category" || page?.type === "brand") && (
              <div className="w-[30%]">
                <div className="mb-4">
                  <Filter />
                </div>
                <div
                  className={`input__field ${
                    highlightInputFieldForFilter ? "bg-gray-200" : "bg-white"
                  }`}
                  onDragOver={(e) => {
                    handleOnDropForFilter(e);
                  }}
                >
                  <PlusIcon />
                </div>
                <div
                  className={`${
                    filterWidgets?.length > 0 && "pt-3 mt-3 border-t"
                  }`}
                >
                  {filterWidgets?.map((item, index) => {
                    const active = item.id === choosenItem?.id;
                    const Comp =
                      item.Component ||
                      widgetData[item.name]?.Component ||
                      Banner;

                    return (
                      <div
                        key={index}
                        className={
                          "relative border-b pb-3 last:pb-0 mb-3 last:mb-0 " +
                          (active ? activeWidgetClass : "last:border-none")
                        }
                      >
                        <div
                          onClick={() => {
                            handleOnClick(
                              {
                                ...item,
                                wType: "sidebar",
                              },
                              index
                            );
                          }}
                          onDragStart={(e) => {
                            draqSideItem.current = index;
                            setDragItemIntoMainList(false);
                            setDragStart(true);
                          }}
                          onDragEnter={() => {
                            handleDragEnterForSideWidget(item, index);
                            setDragEnter(true);
                          }}
                          onDragEnd={handleSortForSideWidget}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            const target = e.target as HTMLImageElement;
                            target.style.opacity = "1";
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            const target = e.target as HTMLImageElement;
                            target.style.opacity = "0.2";
                          }}
                          onDrop={(e) => handleOnDropForPushItem(e, index)}
                          draggable="true"
                        >
                          <Comp />
                          {/* {item.name === 'title' ? <Title /> : item.name === 'slider' ? <MainSlider /> : item.name === 'banner' ? <Banner /> : item.name === '3-collum-images' ? <ThreeCollumsImages /> : item.name === 'populer-product' ? <PopulerProduct /> : item.name === 'categories' ? <Category /> : item.name === 'product-row' ? <ProductRow /> : item.name === 'one-collum-add' ? <OneCollumAdd /> : item.name === 'two-collum-add' ? <TwoCollumAdd /> : item.name === 'brand-list' ? <Brands /> : item.name === 'seller-list' ? <Sellers /> : item.name === 'new-arrival' ? <NewArrival /> : item.name === 'product-slider' ? <ProductSlider /> : item.name === 'todays-deal' ? <TodaysDeal /> : item.name === 'highlighted-shop' ? <HighlightedShop /> : item.name === 'best-selling' ? <BestSellingProducts /> : item.name === 'single-product' ? <SingleProduct /> : item.name === 'electronics-applience' ? <ElectronicsApplience /> : item.name === 'seller-profile' ? <SellerProfile /> : item.name === 'content-editor' ? <ContentEditor /> : item.name === 'product-row-limit' ? <ProductsRowLimit /> : item.name === 'in-demand' ? <InDemand /> : item.name === widgetNames.infiniteProductRow ? <ProductsRowLimit /> : item.name === widgetNames.smallWideBanner ? <SmallWideBanner /> : item.name === widgetNames.banners ? <ThreeCollumsImages /> : item.name === widgetNames.trendingProducts ? <TrendingProducts /> : item.name === widgetNames.threeBannerProducts ? <ThreeBannersProducts /> : item.name === widgetNames.filterProducts ? <FilterProducts /> : item.name === widgetNames.randomSizeBanners ? <RandomBanners /> : null} */}
                        </div>
                        <div
                          onClick={() => handleRemoveFilterWidget(item)}
                          className="absolute z-10 -top-3 -right-3 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
                        >
                          <CloseIcon />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              className={
                page?.type === "category" || page?.type === "brand"
                  ? "w-[70%]"
                  : "w-full"
              }
            >
              <div
                className={`input__field ${
                  highlightInputField ? "bg-gray-200" : "bg-white"
                }`}
                onDragOver={(e) => handleOnDrop(e)}
              >
                <PlusIcon />
              </div>
              <div
                className={`${mainWidgets?.length > 0 && "pt-3 mt-3 border-t"}`}
              >
                {mainWidgets?.map((item, index) => {
                  const active = item.id === choosenItem?.id;
                  const Comp =
                    item.Component ||
                    widgetData[item.name]?.Component ||
                    Banner;

                  return (
                    <div
                      key={index}
                      className={
                        "relative border-b pb-3 last:pb-0 mb-3 last:mb-0 " +
                        (active ? activeWidgetClass : "last:border-none")
                      }
                    >
                      <div
                        onClick={() => {
                          handleOnClick(
                            {
                              ...item,
                              wType: "widgets",
                            },
                            index
                          );
                        }}
                        onDragStart={(e) => {
                          dragItem.current = index;
                          setDragItemIntoMainList(false);
                          setDragStart(true);
                        }}
                        onDragEnter={() => {
                          handleDragEnter(item, index);
                          setDragEnter(true);
                        }}
                        onDragEnd={handleSort}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          const target = e.target as HTMLImageElement;
                          target.style.opacity = "1";
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                          const target = e.target as HTMLImageElement;
                          target.style.opacity = "0.2";
                        }}
                        onDrop={(e) => handleOnDropForPushItem(e, index)}
                        draggable="true"
                      >
                        <Comp />
                        {/* {item.name === 'title' ? <Title /> : item.name === 'slider' ? <MainSlider /> : item.name === 'banner' ? <Banner /> : item.name === '3-collum-images' ? <ThreeCollumsImages /> : item.name === 'populer-product' ? <PopulerProduct /> : item.name === 'categories' ? <Category /> : item.name === 'product-row' ? <ProductRow /> : item.name === 'one-collum-add' ? <OneCollumAdd /> : item.name === 'two-collum-add' ? <TwoCollumAdd /> : item.name === 'brand-list' ? <Brands /> : item.name === 'seller-list' ? <Sellers /> : item.name === 'new-arrival' ? <NewArrival /> : item.name === 'product-slider' ? <ProductSlider /> : item.name === 'todays-deal' ? <TodaysDeal /> : item.name === 'highlighted-shop' ? <HighlightedShop /> : item.name === 'best-selling' ? <BestSellingProducts /> : item.name === 'single-product' ? <SingleProduct /> : item.name === 'electronics-applience' ? <ElectronicsApplience /> : item.name === 'seller-profile' ? <SellerProfile /> : item.name === 'content-editor' ? <ContentEditor /> : item.name === 'product-row-limit' ? <ProductsRowLimit /> : item.name === 'in-demand' ? <InDemand /> : item.name === widgetNames.lastVisitedProducts ? <ProductRow /> : item.name === widgetNames.infiniteProductRow ? <ProductsRowLimit /> : item.name === widgetNames.smallWideBanner ? <SmallWideBanner /> : item.name === widgetNames.banners ? <ThreeCollumsImages /> : item.name === widgetNames.trendingProducts ? <TrendingProducts /> : item.name === widgetNames.threeBannerProducts ? <ThreeBannersProducts /> : item.name === widgetNames.filterProducts ? <FilterProducts /> : item.name === widgetNames.randomSizeBanners ? <RandomBanners /> : item.name === widgetNames.services ? <ServicesBanner /> : null} */}

                        {!dragStart && (
                          <div
                            className={`
                                                        absolute w-full h-full top-0 left-0 z-10 
                                        bg-black/30 opacity-0 hover:opacity-100 transition-opacity
                                        flex flex-col items-start p-4 gap-4 flex-wrap`}
                          >
                            <button
                              className={`p-1.5 py-1 text-sm rounded-lg border bg-white border-white text-black hover:text-black flex gap-2`}
                              onClick={handleCloneWidget(item, index)}
                            >
                              <FaClone />
                              <p>Clone</p>
                            </button>

                            {/* <button 
                                        className={`p-1.5 text-sm rounded-lg border bg-white border-white text-black hover:text-black flex gap-2`}
                                        onClick={handleCloneWidget(item, index)}
                                    >
                                        <FaRegCopy />
                                        <p>Copy</p>
                                    </button> */}
                          </div>
                        )}
                      </div>
                      <div
                        onClick={() => handleRemoveWidget(item)}
                        className="absolute z-50 -top-3 -right-3 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="pagedetails__col--three">
          {page?.type === "category" && (
            <div className="bg-white drop-shadow-lg rounded-md p-2 mb-6">
              <div className="text-sm tracking-wide mb-1">Page Category :</div>
              {mainPageCategory?.map((item: PageCategory, index) => {
                return (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-3 bg-white drop-shadow-lg mb-3 rounded-md text-sm font-light"
                  >
                    <div className="truncate pr-6 w-[80%]">
                      {item.name}({item.slug})
                    </div>
                    <div className="w-[20%]">ID: {item.id}</div>
                    <div
                      className="absolute z-10 -top-2 -right-2 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
                      onClick={() => handleDeletePageCategory(index)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                );
              })}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Page Category"
                  onChange={handlePageCategorySearch}
                />
                {showPageCategory && (
                  <div
                    ref={refPageCategoryResult}
                    className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white my-2 px-2 divide-y z-10"
                  >
                    {pageCategories?.map((item, index) => {
                      return (
                        <div
                          onClick={() => handleChoosePageCategory(item)}
                          key={index}
                          className="p-2 text-sm tracking-wide truncate cursor-pointer"
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className="w-full bg-green-500 text-white text-center text-sm tracking-wider py-2 rounded-md mt-2 cursor-pointer"
                  onClick={handleSavePageCategory}
                >
                  Add Category
                </div>
              </div>

              <div className=" pt-4">
                <BrandsFilterSort
                  brands={pageSettings?.brands}
                  rest={pageSettings?.rest}
                  onUpdate={(data, rest) => {
                    setPageSettings({
                      brands: data.map((item) => ({
                        id: item.id,
                        name: item.name,
                      })),
                      rest: rest,
                    });
                  }}
                />
              </div>
            </div>
          )}
          {page?.type === "brand" && (
            <div className="bg-white drop-shadow-lg rounded-md p-2 mb-6">
              <div className="text-sm tracking-wide mb-1">Page Brand :</div>
              {mainPageBrand?.map((item: PageCategory, index) => {
                return (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-3 bg-white drop-shadow-lg mb-3 rounded-md text-sm font-light"
                  >
                    <div className="truncate pr-6 w-[80%]">
                      {item.name}({item.slug})
                    </div>
                    <div className="w-[20%]">ID: {item.id}</div>
                    <div
                      className="absolute z-10 -top-2 -right-2 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
                      onClick={() => handleDeletePageBrand(index)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                );
              })}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Page Category"
                  onChange={handlePageBrandSearch}
                />
                {showPageBrand && (
                  <div
                    ref={refPageBrandResult}
                    className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white my-2 px-2 divide-y z-10"
                  >
                    {pageBrands?.map((item, index) => {
                      return (
                        <div
                          onClick={() => handleChoosePageBrand(item)}
                          key={index}
                          className="p-2 text-sm tracking-wide truncate cursor-pointer"
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className="w-full bg-green-500 text-white text-center text-sm tracking-wider py-2 rounded-md mt-2 cursor-pointer"
                  onClick={handleSavePageBrand}
                >
                  Add Brand
                </div>
              </div>
            </div>
          )}
          {(page?.type === "campaign" || page?.type === "campaign-landing") && (
            <div className="bg-white drop-shadow-lg rounded-md p-2 mb-6">
              <div className="text-sm tracking-wide mb-1">Page Campaign :</div>
              {mainPageCampaigns?.map((item: PageCategory, index) => {
                return (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-3 bg-white drop-shadow-lg mb-3 rounded-md text-sm font-light"
                  >
                    <div className="truncate pr-6 w-[80%]">
                      {item.name}({item.slug})
                    </div>
                    <div className="w-[20%]">ID: {item.id}</div>
                    <div
                      className="absolute z-10 -top-2 -right-2 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
                      onClick={() => handleDeletePageCampaigns(index)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                );
              })}
              <div className="relative">
                <input
                  value={pageCampaignText}
                  type="text"
                  placeholder="Search Campaigns"
                  onChange={handlePageCampaignsSearch}
                />
                {showPageCampaigns && (
                  <div
                    ref={refPageCampaignsResult}
                    className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white my-2 px-2 divide-y z-10"
                  >
                    {pageCampaigns?.map((item, index) => {
                      return (
                        <div
                          onClick={() => handleChoosePageCampaigns(item)}
                          key={index}
                          className="p-2 text-sm tracking-wide truncate cursor-pointer"
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className="w-full bg-green-500 text-white text-center text-sm tracking-wider py-2 rounded-md mt-2 cursor-pointer"
                  onClick={handleSavePageCampaigns}
                >
                  Add Campaign
                </div>
              </div>
            </div>
          )}
          {!!choosenItem && Object.keys(choosenItem).length > 0 ? (
            <div className="indivisual_widget relative">
              {/* close button for back */}
              <div className="flex justify-start border-b pb-2 mb-1">
                <button
                  className="hover:bg-gray-500 border border-gray-500 text-gray-500 hover:text-white px-2 py-1 rounded-md"
                  onClick={() => {
                    setChoosenItem({} as any);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <WidgetForm
                key={choosenItem.id}
                onUpdate={onFormUpdate}
                choosenItem={choosenItem}
              />
            </div>
          ) : (
            <PageSettings
              settings={pageSettings}
              onSave={() => {
                patchPageData("draft", null, mainPageCategory);
              }}
              onChange={(key, value) => {
                setPageSettings({
                  ...pageSettings,
                  [key]: value,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type SettingsNames =
  | "quickNavigation"
  | "bgColor"
  | "bgImage"
  | "bgSize"
  | "bgPosition"
  | "bgRepeat"
  | "bgAttachment"
  | "fontColor"
  | "metaTitle"
  | "metaImage"
  | "imgAltText"
  | "metaDescription"
  | "metaKeyword";

const pageSettings: SettingItem<SettingsNames>[] = [
  {
    key: "quickNavigation",
    name: "Quick Navigation",
    type: "checkbox",
  },
  {
    key: "bgColor",
    name: "Background Color",
    type: "color",
  },
  {
    key: "bgImage",
    name: "Background Image",
    type: "image",
  },
  {
    key: "fontColor",
    name: "Font Color",
    type: "color",
  },
  {
    key: "metaTitle",
    name: "Meta Title",
    type: "text",
  },
  {
    key: "metaImage",
    name: "Meta Image",
    type: "image",
  },
  {
    key: "imgAltText",
    name: "Image Alt Text",
    type: "text",
  },
  {
    key: "metaDescription",
    name: "Meta Description",
    type: "textarea",
  },
  {
    key: "metaKeyword",
    name: "Meta Keyword",
    type: "text",
    placeholder: "gng, mobile, iphone",
  },
];

const bgImageSettings: SettingItem<SettingsNames>[] = [
  {
    key: "bgSize",
    name: "Background Size",
    type: "select",
    options: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Auto",
        value: "auto",
      },
      {
        name: "Cover",
        value: "cover",
      },
      {
        name: "Contain",
        value: "contain",
      },
      {
        name: "100%",
        value: "100%",
      },
    ],
  },
  {
    key: "bgPosition",
    name: "Background Position",
    type: "select",
    options: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Center",
        value: "center",
      },
      {
        name: "Top",
        value: "top",
      },
      {
        name: "Bottom",
        value: "bottom",
      },
      {
        name: "Left",
        value: "left",
      },
      {
        name: "Right",
        value: "right",
      },
    ],
  },
  {
    key: "bgRepeat",
    name: "Background Repeat",
    type: "select",
    options: [
      {
        name: "None",
        value: "",
      },
      {
        name: "No Repeat",
        value: "no-repeat",
      },
      {
        name: "Repeat",
        value: "repeat",
      },
      {
        name: "Repeat X",
        value: "repeat-x",
      },
      {
        name: "Repeat Y",
        value: "repeat-y",
      },
    ],
  },
  {
    key: "bgAttachment",
    name: "Background Attatchment",
    type: "select",
    options: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Scroll",
        value: "scroll",
      },
      {
        name: "Fixed",
        value: "fixed",
      },
    ],
  },
];

const PageSettings = ({
  settings,
  onChange,
  onSave,
}: {
  settings?: SettingItems<SettingsNames>;
  onChange?: (key: SettingsNames, value: any) => void;
  onSave?: () => void;
}) => (
  <div className="">
    <SettingFormItems
      settingItems={pageSettings}
      title="Page Settings"
      onChange={(key, value) => onChange?.(key as any, value)}
      settings={settings}
    />

    {settings?.bgImage && (
      <SettingFormItems
        settingItems={bgImageSettings}
        title="Background Image Settings"
        onChange={(key, value) => onChange?.(key as any, value)}
        settings={settings}
      />
    )}

    <div>
      <button onClick={onSave} className="mainSliderForm__btn">
        save
      </button>
    </div>
  </div>
);

export default PageDetails;
