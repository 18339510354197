import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { getCategoryList } from "../../../utils/requests";
import { Widget } from "../../../widgetList/widgetList";
import SortList from "../../ListSort";
import PopupWrapper from "../../PopupWrapper";
import Category from "../../Widgets/Category";
import CloseIcon from "../../icons/CloseIcon";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

interface Category {
	id: number;
	logo: string;
	name: string;
	slug: string;
}

const FomrForTile: FC<Props> = ({ choosenItem, onUpdate }) => {
	console.log("choosenItem =", choosenItem);

	const dispatch = useDispatch();

	const [active, setActive] = useState(false);
	const [text, setText] = useState("");
	const [categories, setCategories] = useState<Category[]>([]);
	const [show, setShow] = useState(true);
	const [choosenCategories, setChoosenCategories] = useState<Category[]>([]);
	const [categoryArray, setCategoryArray] = useState<number[]>([]);

	useEffect(() => {
		setActive(choosenItem.active);
		setCategoryArray(choosenItem.category || []);
		setChoosenCategories(choosenItem.category || []);
	}, [choosenItem]);

	useEffect(() => {
		const abortController = new AbortController();

		const timeout = setTimeout(() => {
			try {
				getCategoryList({
					search: text,
					status: "active",
				}).then((data) => {
					setCategories(data.data as any);
					setShow(true);
				});
			} catch (err) {
				console.error("err", err);
			}
		}, 200);

		return () => {
			clearTimeout(timeout);

			try {
				abortController.signal.throwIfAborted();

				if (abortController.signal.aborted === false)
					abortController.abort();
			} catch (error) {}
		};
	}, [text]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		// getCategories(e.target.value);
	};

	// const getCategories = async (text: string) => {
	// 	checkToken();
	// 	getCategoryList({
	// 		search: text,
	// 		status: "active",
	// 	}).then((data) => {
	// 		setCategories(data.data as any);
	// 		setShow(true);
	// 	});
	// };

	const hideCategoryList = () => {
		setShow(false);
	};

	const handleClick = (category: Category, index: number) => {
		const newArray = [...choosenCategories];
		newArray.push(category);
		setChoosenCategories(newArray.reverse());

		// let newCategoryArray:number[] = []
		// newArray.reverse().forEach((item: Category)=>{
		//     newCategoryArray.push(item.id)
		// })
		// setCategoryArray(newCategoryArray)

		const newCategoryArray = [...categoryArray];
		newCategoryArray.push(category.id);
		setCategoryArray(newCategoryArray);
		setText("");
	};

	const removeCategory = (index: number) => {
		const filteredCategories = choosenCategories.filter(
			(_, i) => i !== index
		);
		setChoosenCategories(filteredCategories);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const categoryObj = {
			name: "categories",
			active: active,
			category: choosenCategories,
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		onUpdate(categoryObj);
	};

	const handleRemoveId = (index: number) => {
		const filteredCategory = choosenCategories.filter(
			(_, i) => i !== index
		);
		setChoosenCategories(filteredCategory);
	};

	const ref = useDetectClickOutside({ onTriggered: hideCategoryList });

	return (
		<>
			{choosenItem.name === "categories" ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<h1 className="title capitalize">Categories</h1>
					{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
					<div>
						{categoryArray.length > 0 && (
							<div className="">
								<div className="capitalize flex justify-between">
									<p className="capitalize">
										selected categories:
									</p>

									<button
										className="bg-blue-500 text-white px-2 py-1 rounded-md shadow-lg"
										type="button"
										onClick={() => {
											dispatch(
												setGlobalPopupElement(
													<PopupWrapper>
														<SortList
															data={
																choosenCategories
															}
															renderElement={(
																item
															) => (
																<div className="flex jusbetween items-center gap-2">
																	<img
																		className="rounded-md mb-1 object-contain"
																		src={
																			process
																				.env
																				.REACT_APP_IMAGE_URL +
																			item?.logo
																		}
																		alt=""
																		width={
																			50
																		}
																		height={
																			50
																		}
																	/>
																	<div>
																		<p className="text-sm tracking-wide text-center border-b-2">
																			{
																				item?.name
																			}
																		</p>
																		<p className="text-sm tracking-wide text-center border-b-2">
																			{
																				item?.slug
																			}
																		</p>
																	</div>
																</div>
															)}
															updateData={(
																data
															) => {
																setChoosenCategories(
																	data
																);
																dispatch(
																	setGlobalPopupElement(
																		null
																	)
																);
															}}
														/>
													</PopupWrapper>
												)
											);
										}}>
										Sort
									</button>
								</div>
								<div className="mt-4">
									{choosenCategories.map(
										(item: Category, index: number) => {
											return (
												<div
													key={index}
													className="relative h-12 bg-white shadow-lg rounded-md mb-4 flex items-center w-full">
													<div className="flex items-center justify-between text-sm font-light tracking-wide w-full px-3">
														<div className="w-[80%] truncate">
															{item.name}(
															{item.slug})
														</div>
														<div className="w-[20%]">
															ID:{item.id}
														</div>
													</div>
													<div
														onClick={() =>
															handleRemoveId(
																index
															)
														}
														className="absolute -top-3 -right-3 bg-white text-red-500 rounded-full shadow-lg p-1 cursor-pointer">
														<CloseIcon />
													</div>
												</div>
											);
										}
									)}
								</div>
							</div>
						)}
					</div>
					<div>
						{/* <div>
                    {
                        choosenCategories.length > 0 && 
                        <div>
                            {
                                choosenCategories.map((choosenCategory, index) => {
                                    return(
                                        <div key={index} className="bg-white shadow-lg mb-3 px-3 rounded-md relative">
                                            <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                <div className='text-sm font-light tracking-wide truncate'>{choosenCategory.name}</div>
                                            </div>
                                            <div onClick={() => removeCategory(index)} className='absolute z-10 -top-3 -right-3 bg-white text-red-500 rounded-full p-1 shadow-lg cursor-pointer'>
                                                <CloseIcon/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div> */}
						<div>
							<input
								value={text}
								type="text"
								placeholder="Search categories..."
								onChange={handleSearch}
							/>
						</div>
						<div>
							{categories.length > 0 && show && (
								<div
									className="divide-y max-h-64 min-h-fit overflow-y-scroll"
									ref={ref}>
									{categories.map((category, index) => {
										return (
											<div
												key={index}
												onClick={() =>
													handleClick(category, index)
												}>
												<div className="flex items-center gap-4 py-2 cursor-pointer">
													<img
														className="w-10 h-10 rounded-full object-cover object-center"
														src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png"
														alt=""
													/>
													<div>{category.id}</div>
													<div className="text-sm font-light tracking-wide truncate">
														{category.name}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FomrForTile;
